import * as React from 'react';
import { IModifierGroupLocal, IModifierLocal } from '../../interfaces';
import { IModifierEditabilityPayload } from '../action-creators';
import ModifierItem from '../components/customize-modifier-item';

export interface IIngredientsListProps {
    modifierGroup: IModifierGroupLocal;
    isBelowMaxQuantity(): boolean;
    isAboveMinQuantity(): boolean;
}

export interface IIngredientsListDispatchProps {
    increase?(payload: IModifierEditabilityPayload): void;
    decrease?(payload: IModifierEditabilityPayload): void;
}

type Props = IIngredientsListProps & IIngredientsListDispatchProps;
const IngredientsList: React.FC<Props> = (props: Props) => {
    const { modifierGroup, increase, decrease, isBelowMaxQuantity, isAboveMinQuantity } = props;

    const _renderModiferItems = () =>
        modifierGroup.currentModifiers
            .filter(modifier => modifier.section === modifierGroup.leftRightWhole)
            .map((item: IModifierLocal, index: number) => (
                <ModifierItem
                    key={index}
                    modifierItem={item}
                    increase={increase}
                    decrease={decrease}
                    isBelowMaxQuantity={isBelowMaxQuantity}
                    isAboveMinQuantity={isAboveMinQuantity}
                />
            ));

    return <div className='as-modifiers-items-container ingredients-item-container'>{_renderModiferItems()}</div>;
};

export default IngredientsList;
