import { connect } from 'react-redux';
import { IState } from '../../interfaces';
import { decreaseClick, increaseClick, itemCheck, itemClick } from '../action-creators';
import IngredientsList, { IIngredientsListDispatchProps, IIngredientsListMapStateToProps } from '../components/ingredient-list';

const getIngredients = (state: IState) => {
    const selectedSection = state.customizeIngredients.leftRightWhole;
    return state.customizeIngredients.ingredientsForDifferentSections.filter(item => {
        return item.section === selectedSection;
    });
};

const mapStateToProps = (state: IState): IIngredientsListMapStateToProps => {
    return {
        ingredients: getIngredients(state)
    };
};

const mapDispatchToProps: IIngredientsListDispatchProps = {
    itemClick,
    itemCheck,
    increaseClick,
    decreaseClick
};

export default connect(mapStateToProps, mapDispatchToProps)(IngredientsList);
