import * as React from 'react';

export interface IIngredientCheckBoxProps {
    image?: string;
    name: string;
    checked: boolean;
    check?(): void;
}

const handleOnErrorImg = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const target = e.target as HTMLImageElement;
    target.style.visibility = 'hidden';
};

const IngredientCheckBox: React.FunctionComponent<IIngredientCheckBoxProps> = (props: IIngredientCheckBoxProps) => {
    const { name, checked, check, image } = props;
    let itemTypeClassName = 'multi-select';
    itemTypeClassName = `ms-refine-submenu-item ${itemTypeClassName}`;
    const inputType = 'checkbox';
    itemTypeClassName = checked ? `${itemTypeClassName}-checked` : itemTypeClassName;
    return (
        <div className='item-row ms-refine-submenu-item-row' id={name}>
            {image && <img className='ms-refine-submenu-item as-modifiers-image' src={image} alt={name} onError={handleOnErrorImg} />}
            <span className='item-name ms-refine-submenu-item__label'>{name}</span>
            <a onClick={check} className={itemTypeClassName} role={inputType} aria-checked={checked} />
        </div>
    );
};

export default IngredientCheckBox;
