import { EditabilityText, IIngredientData, IIngredientWithSections, IState, LeftRightWhole } from '../../interfaces';

const selectedWholeItem = (item: IIngredientWithSections) => item.checked && item.section === LeftRightWhole.whole;

const selectedLeftItem = (item: IIngredientWithSections) => item.checked && item.section === LeftRightWhole.left;

const selectedRightItem = (item: IIngredientWithSections) => item.checked && item.section === LeftRightWhole.right;

const hasSubstituted = (item: IIngredientWithSections, defaultIngredients: IIngredientData[]): boolean =>
    defaultIngredients.some(defaultIngredient => item.LineNum === defaultIngredient.LineNum && item.Name !== defaultIngredient.Name);

export const getSelectedWholeItemNames = (state: IState): string[] =>
    state.customizeIngredients.ingredientsForDifferentSections.filter(selectedWholeItem).map(item => {
        if (item.editabilityOption === EditabilityText.Normal) {
            return hasSubstituted(item, state.customizeIngredients.defaultIngredients) ? `SUB ${item.Name}` : item.Name;
        }
        return `${item.editabilityOption} ${item.Name}`;
    });

export const getSelectedLeftItemNames = (state: IState): string[] =>
    state.customizeIngredients.ingredientsForDifferentSections.filter(selectedLeftItem).map(item => {
        if (item.editabilityOption === EditabilityText.Normal) {
            return hasSubstituted(item, state.customizeIngredients.defaultIngredients) ? `SUB ${item.Name}` : item.Name;
        }
        return `${item.editabilityOption} ${item.Name}`;
    });

export const getSelectedRightItemNames = (state: IState): string[] =>
    state.customizeIngredients.ingredientsForDifferentSections.filter(selectedRightItem).map(item => {
        if (item.editabilityOption === EditabilityText.Normal) {
            return hasSubstituted(item, state.customizeIngredients.defaultIngredients) ? `SUB ${item.Name}` : item.Name;
        }
        return `${item.editabilityOption} ${item.Name}`;
    });
