import * as React from 'react';
import { IProductComponentWithData } from '../../interfaces';
import { ILoadComponentsPayload } from '../action-creators';
import SlotComponentsList from '../containers/slot-list';

interface IOwnProps {
    components: IProductComponentWithData[];
    onSlotchange?(payload: IProductComponentWithData[] | null, selectedIndex: number): void;
}

interface ICustomizeSlotComponentsBoxDispatchProps {
    loadSlotComponents?(payload: ILoadComponentsPayload): void;
}

interface IProps extends ICustomizeSlotComponentsBoxDispatchProps, IOwnProps {}

const CustomizeSlotComponentsBox: React.FC<IProps> = (props: IProps): JSX.Element => {
    let defaultComponents = [];
    let selectedIndex: number = 999;
    let i: number = 0;
    if (props.components) {
        defaultComponents = props.components;
        for (i = 0; i < defaultComponents.length; i++) {
            if (defaultComponents[i].checked) {
                selectedIndex = i;
            }
        }
    }
    props.components && props.onSlotchange && props.onSlotchange(props.components, selectedIndex);

    React.useEffect(() => {
        props.loadSlotComponents &&
            props.loadSlotComponents({
                components: props.components,
                onSlotchange: props.onSlotchange
            });
    }, [props.components]);

    return (
        <div>
            <SlotComponentsList />
        </div>
    );
};

export default CustomizeSlotComponentsBox;
