import { ActionTypes } from '../../actions-types';
import { IProductComponents, IProductComponentWithData } from '../../interfaces';
import { CustomizeRecipeActions } from '../action-creators';

const initialState: IProductComponents = {
    components: []
};

export default (state = initialState, action: CustomizeRecipeActions): IProductComponents => {
    switch (action.type) {
        case ActionTypes.LOAD_RECIPES:
            return {
                ...state,
                components: []
            };
        case ActionTypes.RECIPE_CHECK:
            let componentSlots: IProductComponentWithData[] = [];
            if (action.payload.recipe.slots) {
                componentSlots = action.payload.recipe.slots;
            }
            return {
                ...state,
                components: componentSlots
            };
        case ActionTypes.LOAD_SLOTS:
            return {
                ...state,
                components: action.payload.components
            };
        case ActionTypes.SLOT_CHECK:
            const productId = action.payload.component.slot.ProductId;
            const components = state.components.filter(item => {
                if (item.slot.ProductId === productId) {
                    item.checked = true;
                } else {
                    item.checked = false;
                }
                return item;
            });

            return {
                ...state,
                components: components
            };
        default:
            return state;
    }
};
