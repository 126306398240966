import * as React from 'react';
import { IModifierGroup, IModifierLine } from '../../interfaces';
import { ILoadModifiersPayload } from '../action-creators';
import ModifiersCollection from '../containers/customize-modifiers-collection';

interface IOwnProps {
    modifiers: IModifierGroup[];
    modifierLine?: IModifierLine[];
}

interface ICustomizeModifiersDispatchProps {
    loadModifiers?(payload: ILoadModifiersPayload): void;
}

interface IProps extends ICustomizeModifiersDispatchProps, IOwnProps {}

const CustomizeModifiers: React.FC<IProps> = (props: IProps): JSX.Element => {
    React.useEffect(() => {
        props.loadModifiers &&
            props.loadModifiers({
                modifiers: props.modifiers,
                modifierLine: props.modifierLine
            });
    }, [props.modifierLine]);

    return (
        <div className='as-modifiers-container'>
            <ModifiersCollection />
        </div>
    );
};

export default CustomizeModifiers;
