import { buildCacheKey } from '@msdyn365-commerce-modules/retail-actions';
import {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    ICommerceApiSettings
} from '@msdyn365-commerce/core';
import { getVariantsByComponentsInSlotsAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { ComponentInSlotRelation, SimpleProduct } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';

/**
 * Input class for get variants by components in slots data action
 */
export class GetVariantsByComponentsInSlotsExtInput implements IActionInput {
    public recordId: number;
    public channelId: number;
    public componentSlotRelations: ComponentInSlotRelation[];
    private apiSettings: ICommerceApiSettings;

    constructor(recordId: number, channelId: number, componentSlotRelations: ComponentInSlotRelation[], apiSettings: ICommerceApiSettings) {
        this.recordId = recordId;
        this.channelId = channelId;
        this.componentSlotRelations = componentSlotRelations;
        this.apiSettings = apiSettings;
    }

    public getCacheKey = () => buildCacheKey(`SimpleProduct`, this.apiSettings);
    public getCacheObjectType = () => 'SimpleProduct';
    public dataCacheType = (): CacheType => 'request';
}

export async function getVariantsByComponentsInSlotsAction(
    input: GetVariantsByComponentsInSlotsExtInput,
    ctx: IActionContext
): Promise<SimpleProduct[]> {
    return getVariantsByComponentsInSlotsAsync(
        { callerContext: ctx, queryResultSettings: {} },
        input.recordId,
        input.channelId,
        input.componentSlotRelations
    );
}

export default createObservableDataAction({
    action: <IAction<SimpleProduct[]>>getVariantsByComponentsInSlotsAction
});
