import * as React from 'react';
import { IIngredientWithSections } from '../../interfaces';
import { IEditabilityPayload, IItemCheckPayload } from '../action-creators';
import IngredientItem from './ingredient-item';

export interface IIngredientsListMapStateToProps {
    ingredients: IIngredientWithSections[];
}

export interface IIngredientsListDispatchProps {
    itemClick?(payload: IIngredientWithSections): void;
    itemCheck?(payload: IItemCheckPayload): void;
    increaseClick?(payload: IEditabilityPayload): void;
    decreaseClick?(payload: IEditabilityPayload): void;
}

type Props = IIngredientsListMapStateToProps & IIngredientsListDispatchProps;
const IngredientsList: React.FC<Props> = (props: Props) => {
    const { ingredients, itemCheck, increaseClick, decreaseClick, itemClick } = props;

    const _renderIngredients = () =>
        ingredients.map((ingredient: IIngredientWithSections, index: number) => (
            <IngredientItem
                key={index.toString()}
                item={ingredient}
                check={itemCheck}
                increase={increaseClick}
                decrease={decreaseClick}
                click={itemClick}
            />
        ));

    return <div className='as-modifiers-items-container ingredients-item-container'>{_renderIngredients()}</div>;
};

export default IngredientsList;
