import { getSelectedVariant, SelectedVariantInput } from '@msdyn365-commerce-modules/retail-actions';
import { ITelemetryContent } from '@msdyn365-commerce-modules/utilities';
import { IComponent, IComponentProps, IGridSettings, IImageSettings, msdyn365Commerce } from '@msdyn365-commerce/core';
import { getCartState, ICartActionResult } from '@msdyn365-commerce/global-state';
import {
    Address,
    CartLine,
    CommerceProperty,
    CommercePropertyValue,
    OrgUnitLocation,
    ProductAvailableQuantity,
    ProductDimension,
    ProductPrice,
    SimpleProduct
} from '@msdyn365-commerce/retail-proxy';
import { AttributeValueBase } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import classnames from 'classnames';
import React, { useState } from 'react';
import { IModifierLineRequest, ProductType } from './components/interfaces';

import { updateCartLinesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';

import lifestyleAddProductToCart from '../buybox-ext/lifestyle-add-product-to-cart';
import { Constants } from '../lifestyle-helper-classes/constant';
import ExtensionPropReaderHelper from '../lifestyle-helper-classes/extension-prop-reader';

export interface ILifestyleAddToCartComponentProps
    extends IComponentProps<{
        product: SimpleProduct;
        price?: ProductPrice;
    }> {
    className?: string;
    addToCartText: string;
    updateCartText: string;
    outOfStockText: string;
    disabled?: boolean;
    quantity?: number;
    navigationUrl?: string;
    productAvailability?: ProductAvailableQuantity;
    getSelectedProduct?: Promise<SimpleProduct | null>;
    imageSettings?: IImageSettings;
    gridSettings?: IGridSettings;
    isLoading?: boolean;
    isUpdatingDimension?: boolean;
    isAddServiceItemToCart?: boolean;
    dialogStrings?: {
        goToCartText: string;
        continueShoppingText: string;
        headerItemOneText: string;
        headerItemFormatText: string;
        headerMessageText: string;
        freePriceText: string;
        originalPriceText: string;
        currentPriceText: string;
    };
    telemetryContent?: ITelemetryContent;
    modifierLineRequest?: IModifierLineRequest[];
    updateCartLineNumber?: number;
    doNotRefreshCartState?: boolean;
    productUrl?: string;
    isOrder?: boolean;
    onAdd?(result: ICartActionResult): void;
    onError?(result: ILifestyleAddToCartFailureResult): void;
    changeUpdatingDimension?(isUpdatingDimension: boolean): void;
    forceDeliveryModeSelection?(): void;
}

export declare type ILifestyleCartActionFailureReason = 'EMPTYINPUT' | 'MISSINGDIMENSION' | 'OUTOFSTOCK' | 'CARTACTIONFAILED';

export interface ILifestyleAddToCartFailureResult {
    failureReason: ILifestyleCartActionFailureReason;
    stockLeft?: number;
    cartActionResult?: ICartActionResult;
    missingDimensions?: ProductDimension[];
}

export interface ILifestyleAddtoCartComponent extends IComponent<ILifestyleAddToCartComponentProps> {
    onClick(): (event: React.MouseEvent<HTMLElement>, props: ILifestyleAddToCartComponentProps) => void;
}

const getSimpleProduct = async (props: ILifestyleAddToCartComponentProps, product: SimpleProduct) => {
    if (!product) {
        return;
    }

    const actionContext = props.context.actionContext;
    const channelId = actionContext.requestContext.apiSettings.channelId;
    const mappedDimensions: ProductDimension[] | undefined = [];

    // tslint:disable-next-line:no-unnecessary-local-variable
    const newProduct = await getSelectedVariant(new SelectedVariantInput(product.RecordId, channelId, mappedDimensions), actionContext);

    return newProduct;
};

const onClick = async (
    _event: React.MouseEvent<HTMLElement>,
    props: ILifestyleAddToCartComponentProps,
    setDisabled: (disabled: boolean) => void
): Promise<void> => {
    const {
        getSelectedProduct,
        isOrder,
        navigationUrl,
        productUrl,
        updateCartLineNumber,
        data: { product }
    } = props;
    const cartError = addToCartError(props);
    // ProductToAdd is a simple product, but it does not have a ProductTypeValue
    let productToAdd = product;
    let succeeded: boolean = false;

    // It is necessary to getSimpleProduct to obtain a ProductTypeValue despite that ProductToAdd is also of type SimpleProduct
    const simpleProduct = await getSimpleProduct(props, product);
    if (isOrder && simpleProduct) {
        const noneProductType = simpleProduct && simpleProduct.ProductTypeValue === ProductType.None;
        const isProductStandAlone = simpleProduct && simpleProduct.ProductTypeValue === ProductType.Standalone;

        if (cartError) {
            propogateError(props, cartError);
            return;
        }

        if (!noneProductType && !isProductStandAlone) {
            productUrl && window.location.assign(productUrl);
            return;
        }
    }

    if (!(getSelectedProduct === undefined)) {
        productToAdd = (await getSelectedProduct) || product;
    }

    // Disable the button
    setDisabled(true);

    // Verify whether the request is to update/add, and process accordingly
    if (updateCartLineNumber && updateCartLineNumber > 0) {
        await updateProductInCart(props, productToAdd, setDisabled, updateCartLineNumber).then(() => {
            succeeded = true;
        });
    } else {
        await addProductToCart(props, productToAdd, setDisabled).then(() => {
            succeeded = true;
        });
    }

    // Verify if the above operation succeeded and need to redirect page
    if (succeeded && navigationUrl) {
        window.location.assign(navigationUrl);
    }

    // Enable the button
    setDisabled(false);
};

const updateProductInCart = async (
    props: ILifestyleAddToCartComponentProps,
    productToAdd: SimpleProduct,
    setDisabled: (disabled: boolean) => void,
    cartLineNumberToBeUpdated?: number
): Promise<void> => {
    const cartState = await getCartState(props.context.actionContext);
    let refreshCart: boolean = false;
    let attributeValues: AttributeValueBase[] = [];

    if (cartState.cart && cartState.cart.CartLines) {
        const cartLinesToBeUpdated = cartState.cart.CartLines.filter(cartLine => cartLine.LineNumber === cartLineNumberToBeUpdated);
        if (cartLinesToBeUpdated && cartLinesToBeUpdated.length > 0) {
            const cartLineToBeUpdated = cartLinesToBeUpdated[0];

            // Remove the existing modifier lines, if any
            removeExtensionProperty(cartLineToBeUpdated.ExtensionProperties, Constants.MODIFIERLINES_EXT);

            // replace existing product configuration extension properties if any
            if (productToAdd && productToAdd.ExtensionProperties && productToAdd.ExtensionProperties.length > 0) {
                const copyExtensionProps = [
                    Constants.MODIFIERLINEREQUEST_EXT,
                    Constants.PRODUCTCONFIGURATORMODEL_EXT,
                    Constants.PCCURRENTVALUES_EXT,
                    Constants.PCMODELPRICE_EXT,
                    Constants.PCCONFIGURATIONSUMMARY_EXT,
                    Constants.PRODUCTCATEGORYID_EXT,
                    Constants.PRODUCTCONFIGURATIONMODEL_EXT,
                    Constants.PRODUCTCONFIGURATIONRESPONSEDATA_EXT,
                    Constants.SOLVERSTRATEGY_EXT,
                    Constants.DEFAULTVARIANTID_EXT
                ];

                const productConfigurationExtensionProperties = productToAdd.ExtensionProperties.filter(
                    (extensionProps: CommerceProperty) => extensionProps.Key && copyExtensionProps?.includes(extensionProps.Key)
                );

                productConfigurationExtensionProperties.forEach(x => {
                    setExtensionProperty(cartLineToBeUpdated.ExtensionProperties, x.Key!, x.Value!);
                });

                // copy product configurator extension properties to attribute values on cart line item because there was a bug where properties were not sending via extension properties.  We have to send it via attribute values instead
                attributeValues = ExtensionPropReaderHelper.copyPCExtensionPropertiesToAttributeValues(
                    productConfigurationExtensionProperties
                );

                // set updated attribute values
                cartLineToBeUpdated.AttributeValues = attributeValues;
            }

            // Add the Modifier Line Request extension property for CRT to process the same
            addExtensionPropertyModifierLineRequest(cartLineToBeUpdated, props.modifierLineRequest);
            // Update cart line quantity
            cartLineToBeUpdated.Quantity = props.quantity;

            // update cartLines in CRT
            await updateCartLinesAsync({ callerContext: props.context.actionContext }, cartState.cart.Id, cartState.cart.CartLines)
                .then(result => {
                    // update cart success
                    propogateResult(props, { status: 'SUCCESS' });
                    refreshCart = true;
                    return Promise.resolve();
                })
                .catch(() => {
                    // update cart fail
                    propogateError(props, {
                        failureReason: 'CARTACTIONFAILED',
                        cartActionResult: { status: 'FAILED' }
                    });
                    setDisabled(false);
                    return Promise.reject();
                });

            // Check whether a cart refresh is required or not
            if (refreshCart) {
                await cartState.refreshCart({});
            }
        }
    }
};

const addProductToCart = async (
    props: ILifestyleAddToCartComponentProps,
    productToAdd: SimpleProduct,
    setDisabled: (disabled: boolean) => void
): Promise<void> => {
    /* tslint:disable */
    let pickupLocation: OrgUnitLocation | undefined;
    /* tslint:disable */
    let shippingAddress: Address | undefined;

    // Add the Modifier Line Request extension property for CRT to process the same
    if (props.modifierLineRequest) {
        addExtensionPropertyModifierLineRequest(productToAdd, props.modifierLineRequest);
    }

    await lifestyleAddProductToCart(props.context.actionContext, {
        product: productToAdd,
        count: props.quantity,
        location: pickupLocation,
        address: shippingAddress,
        doNotRefreshCartState: props.doNotRefreshCartState,
        copyExtensionProps: [
            Constants.MODIFIERLINEREQUEST_EXT,
            Constants.PRODUCTCONFIGURATORMODEL_EXT,
            Constants.PCCURRENTVALUES_EXT,
            Constants.PCMODELPRICE_EXT,
            Constants.PCCONFIGURATIONSUMMARY_EXT,
            Constants.PRODUCTCATEGORYID_EXT,
            Constants.PRODUCTCONFIGURATIONMODEL_EXT,
            Constants.PRODUCTCONFIGURATIONRESPONSEDATA_EXT,
            Constants.SOLVERSTRATEGY_EXT,
            Constants.DEFAULTVARIANTID_EXT
        ]
    })
        .then(result => {
            // add cart line success
            if (result.status === 'SUCCESS') {
                return Promise.resolve();
            } else {
                // add cart fail
                propogateError(props, {
                    failureReason: 'CARTACTIONFAILED',
                    cartActionResult: result
                });
                setDisabled(false);
                return Promise.reject('Failed to add to Cart');
            }
        })
        .catch(reason => {
            return Promise.reject(reason);
        });
};

const addExtensionPropertyModifierLineRequest = (
    cartline: CartLine | SimpleProduct,
    modifierLineRequest: IModifierLineRequest[] | undefined
) => {
    if (modifierLineRequest && modifierLineRequest.length > 0 && cartline) {
        // update cartLINES in crt
        const commercePropertyValue: CommercePropertyValue = {
            StringValue: JSON.stringify(modifierLineRequest)
        };
        setExtensionProperty(cartline.ExtensionProperties, Constants.MODIFIERLINEREQUEST_EXT, commercePropertyValue);
    }
};

const setExtensionProperty = (extensionProperty: CommerceProperty[] | undefined, key: string, value: CommercePropertyValue): void => {
    if (extensionProperty) {
        const propertyExistsAtIndex = extensionProperty.findIndex(prop => prop.Key === key);
        if (propertyExistsAtIndex >= 0) {
            // The property already exists, update the value
            extensionProperty[propertyExistsAtIndex] = { Key: key, Value: value };
        } else {
            // The property doesn't exists, add the property with value
            extensionProperty.push({ Key: key, Value: value });
        }
    }
};

const removeExtensionProperty = (extensionProperty: CommerceProperty[] | undefined, key: string): boolean => {
    let removed = false;
    if (extensionProperty) {
        const propertyExistsAtIndex = extensionProperty.findIndex(prop => prop.Key === key);
        if (propertyExistsAtIndex >= 0) {
            // The property already exists, update the value
            extensionProperty.splice(propertyExistsAtIndex, 1);
            removed = true;
        }
    }
    return removed;
};

const LifestyleAddToCartComponentActions = {
    onClick: onClick
};

const LifestyleAddToCart: React.FC<ILifestyleAddToCartComponentProps> = (props: ILifestyleAddToCartComponentProps) => {
    const [disabled, setDisabled] = useState(false);
    const onClickHandler = (event: React.MouseEvent<HTMLElement>) => {
        return LifestyleAddToCartComponentActions.onClick(event, props, setDisabled);
    };
    return (
        <button
            className={classnames('msc-add-to-cart ', props.className)}
            aria-label={getLinkText(props)}
            onClick={onClickHandler}
            disabled={
                props.disabled ||
                disabled ||
                shouldShowOutOfStock(props, false) ||
                !ExtensionPropReaderHelper.isPCComplete(props.data?.product?.ExtensionProperties)
            }
        >
            {getLinkText(props)}
        </button>
    );
};

// Set default props
LifestyleAddToCart.defaultProps = {
    quantity: 1
};

const getLinkText = (props: ILifestyleAddToCartComponentProps): string => {
    if (!shouldShowOutOfStock(props, false)) {
        if (props.updateCartLineNumber) {
            return props.updateCartText;
        }

        return props.addToCartText;
    }

    return props.outOfStockText;
};

const addToCartError = (props: ILifestyleAddToCartComponentProps): ILifestyleAddToCartFailureResult | undefined => {
    if (!props.data || !props.data.product.RecordId) {
        // No product exists, won't be able to add to cart
        return { failureReason: 'EMPTYINPUT' };
    }

    if (props.data.product.Dimensions) {
        const missingDimensions = props.data.product.Dimensions.filter(
            dimension => !(dimension.DimensionValue && dimension.DimensionValue.Value)
        );

        if (missingDimensions.length > 0) {
            // At least one dimension with no value exists on the product, won't be able to add to cart
            return {
                failureReason: 'MISSINGDIMENSION',
                missingDimensions: missingDimensions
            };
        }
    }

    if (shouldShowOutOfStock(props, true)) {
        const availableQuantity = (props.productAvailability && props.productAvailability.AvailableQuantity) || 0;
        const stockLeft = Math.max(availableQuantity - props.context.app.config.outOfStockThreshold, 0);

        return { failureReason: 'OUTOFSTOCK', stockLeft: stockLeft };
    }

    // Only allow adding to cart if not showing out of stock
    return undefined;
};

const shouldShowOutOfStock = (props: ILifestyleAddToCartComponentProps, includeCurrentQuantity: boolean): boolean => {
    if (props.context.app.config.enableStockCheck === false) {
        // Out of stock turn off, don't bother showing out of stock
        return false;
    }

    if (!props.data || !props.data.product.RecordId) {
        // No product exists, don't bother showing out of stock
        return false;
    }

    if (props.data.product.Dimensions) {
        if (props.data.product.Dimensions.find(dimension => !(dimension.DimensionValue && dimension.DimensionValue.Value))) {
            // At least one dimension with no value exists on the product, so also don't show out of stock
            return false;
        }
    }

    let quantity: number = 0;
    let outOfStockThreshold: number = 0;
    if (props.context.app.config.outOfStockThreshold) {
        outOfStockThreshold = props.context.app.config.outOfStockThreshold;
    }

    if (props.quantity) {
        quantity = props.quantity;
    }

    if (
        props.productAvailability &&
        props.productAvailability.AvailableQuantity !== undefined &&
        props.productAvailability.AvailableQuantity >= outOfStockThreshold + (includeCurrentQuantity ? quantity : 1)
    ) {
        return false;
    } else {
        // Out of stock
        return true;
    }
};

const propogateResult = (props: ILifestyleAddToCartComponentProps, result: ICartActionResult): void => {
    if (props.onAdd) {
        props.onAdd(result);
    }
};

const propogateError = (props: ILifestyleAddToCartComponentProps, result: ILifestyleAddToCartFailureResult): void => {
    if (props.onError) {
        props.onError(result);
    }
};

// @ts-ignore
// prettier-ignore
const AddToCartComponent: React.FunctionComponent<ILifestyleAddToCartComponentProps> = msdyn365Commerce.createComponent<ILifestyleAddtoCartComponent>(
  'LifestyleAddToCart',
  { component: LifestyleAddToCart, ...LifestyleAddToCartComponentActions}
  );

export default AddToCartComponent;
