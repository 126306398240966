import * as React from 'react';
import { IModifierGroupLocal } from '../../interfaces';
import { IModifierEditabilityPayload, IModifierPizzaSectionPayload } from '../action-creators';
import ModifierItems from '../components/customize-modifier-items';
import ModifiersBoxHeader from '../components/modifiers-box-header';
import PizzaSections from '../components/modifiers-pizza-sections';

// create local state

interface IModifiersGroupProps {
    modifierGroup: IModifierGroupLocal;
}

interface IModifiersGroupDispatchProps {
    pizzaSectionClick?(payload: IModifierPizzaSectionPayload): void;
    increase?(payload: IModifierEditabilityPayload): void;
    decrease?(payload: IModifierEditabilityPayload): void;
}

interface IProps extends IModifiersGroupProps, IModifiersGroupDispatchProps {}

const CustomizeIngredientsBox: React.FC<IProps> = (props: IProps): JSX.Element => {
    const { modifierGroup, pizzaSectionClick, increase, decrease } = props;

    const [totalQuantity, setTotalQuantity] = React.useState<number>(props.modifierGroup.currentQuantity);

    React.useEffect(() => {
        setTotalQuantity(props.modifierGroup.currentQuantity);
    }, [props.modifierGroup.currentQuantity]);

    const isBelowMaxQuantity = (): boolean => {
        // if 0, means no maximum
        if (totalQuantity < modifierGroup.MaxQuantity || modifierGroup.MaxQuantity === 0) {
            setTotalQuantity(totalQuantity + 1);
            return true;
        }
        return false;
    };

    const isAboveMinQuantity = (): boolean => {
        if (totalQuantity > modifierGroup.MinQuantity) {
            setTotalQuantity(totalQuantity - 1);
            return true;
        }
        return false;
    };

    return (
        <div className='as-modifiers-container customize-ingredients-box'>
            <p />
            <ModifiersBoxHeader>
                <div>
                    <h5 className='ms-buybox__customize-ingredients-heading'>{modifierGroup.Description}</h5>
                </div>
                <PizzaSections
                    pizzaSectionClick={pizzaSectionClick}
                    modifierGroupId={modifierGroup.GroupId}
                    allowLeftRight={modifierGroup.AllowLeftRight}
                />
            </ModifiersBoxHeader>
            <ModifierItems
                modifierGroup={modifierGroup}
                increase={increase}
                decrease={decrease}
                isBelowMaxQuantity={isBelowMaxQuantity}
                isAboveMinQuantity={isAboveMinQuantity}
            />
        </div>
    );
};

export default CustomizeIngredientsBox;
