import * as React from 'react';

export interface IRecipeCheckBoxBoxProps {
    name: string;
    checked: boolean;
    check?(): void;
}

const RecipeCheckBox: React.FunctionComponent<IRecipeCheckBoxBoxProps> = (props: IRecipeCheckBoxBoxProps) => {
    const { name, checked, check } = props;
    let itemTypeClassName = 'multi-select';
    itemTypeClassName = `ms-refine-submenu-item ${itemTypeClassName}`;
    const inputType = 'checkbox';
    itemTypeClassName = checked ? `${itemTypeClassName}-checked` : itemTypeClassName;
    return (
        <div className=' item-row ms-refine-submenu-item-row' id={name}>
            <a onClick={check} className={itemTypeClassName} role={inputType} aria-checked={checked} />
            <span className='ms-refine-submenu-item__label ingredient-checkbox-label'>{name}</span>
        </div>
    );
};

export default RecipeCheckBox;
