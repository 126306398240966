import { ActionTypes } from '../../actions-types';
import { IRecipes } from '../../interfaces';
import { CustomizeRecipeActions } from '../action-creators';

const initialState: IRecipes = {
    recipes: []
};

export default (state = initialState, action: CustomizeRecipeActions): IRecipes => {
    switch (action.type) {
        case ActionTypes.LOAD_RECIPES:
            return {
                ...state,
                recipes: action.payload.recipes
            };
        case ActionTypes.RECIPE_CHECK:
            const productId = action.payload.recipe.defaultId;
            const allrecipes = state.recipes.filter(item => {
                if (item.defaultId === productId) {
                    item.checked = true;
                } else {
                    item.checked = false;
                }
                return item;
            });
            return {
                ...state,
                recipes: allrecipes
            };
        case ActionTypes.SLOT_CHECK:
            const productId1 = action.payload.component.defaultProductId;
            const allrecipes1 = state.recipes.filter(item => {
                if (item.defaultId === productId1) {
                    item.checked = true;
                    item.slots?.filter(component => {
                        component.checked = false;
                    });
                } else {
                    item.checked = false;
                }
                return item;
            });
            return {
                ...state,
                recipes: allrecipes1
            };
        case ActionTypes.LOAD_SLOTS:
            const filteredRecipes = state.recipes.filter(item => {
                if (item.checked) {
                    item.slots?.filter(component => {
                        if (component.checked) {
                            component.checked = false;
                        }
                    });
                }
                return item;
            });
            return {
                ...state,
                recipes: filteredRecipes
            };
        default:
            return state;
    }
};
