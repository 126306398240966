import classnames from 'classnames';
import * as React from 'react';
import { IDomainWithValue } from '../../interfaces';
import { ISubItemCheckPayload } from '../action-creators';
import ConfiguratorCheckBox from './configurator-checkbox';
export interface IConfiguratorSubItemProps {
    item: IDomainWithValue;
}

export interface IConfiguratorItemDispatchProps {
    check?(payload: ISubItemCheckPayload): void;
}

type Props = IConfiguratorSubItemProps & IConfiguratorItemDispatchProps;
const ConfiguratorSubItem = (props: Props): JSX.Element => {
    const { item, check } = props;
    const _handleConfiguratorCheckBoxChange = () => {
        if (!item.checked) {
            item.checked;
            check && check({ component: item, checked: item.checked });
        }
    };

    const attributeClass = classnames('pc-item-line', {
        'pc-item-line-selected': item.checked
    });

    return (
        <div className='pc-item '>
            <div role='none' className={`item-line ${attributeClass}`}>
                <ConfiguratorCheckBox
                    selectedValue={item.DisplayValue ? item.DisplayValue : ''}
                    name={''}
                    checked={item.checked}
                    check={_handleConfiguratorCheckBoxChange}
                    image={item.ImageUrl}
                />
            </div>
        </div>
    );
};

export default ConfiguratorSubItem;
