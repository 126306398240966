import { buildCacheKey } from '@msdyn365-commerce-modules/retail-actions';
import {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    ICommerceApiSettings
} from '@msdyn365-commerce/core';
import { getFilteredSlotComponentsAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { ComponentInSlotRelation, ProductComponent } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';

/**
 * Input class for get filtered slot components data action
 */
export class GetFilteredSlotComponentsExtInput implements IActionInput {
    public recordId: number;
    public channelId: number;
    public slotId: number;
    public selectedComponents: ComponentInSlotRelation[];
    private apiSettings: ICommerceApiSettings;

    constructor(
        recordId: number,
        channelId: number,
        slotId: number,
        selectedComponents: ComponentInSlotRelation[],
        apiSettings: ICommerceApiSettings
    ) {
        this.recordId = recordId;
        this.channelId = channelId;
        this.slotId = slotId;
        this.selectedComponents = selectedComponents;
        this.apiSettings = apiSettings;
    }

    public getCacheKey = () => buildCacheKey(`ProductComponent`, this.apiSettings);
    public getCacheObjectType = () => 'ProductComponent';
    public dataCacheType = (): CacheType => 'request';
}

export async function getFilteredSlotComponentsAction(
    input: GetFilteredSlotComponentsExtInput,
    ctx: IActionContext
): Promise<ProductComponent[]> {
    return getFilteredSlotComponentsAsync(
        { callerContext: ctx, queryResultSettings: {} },
        input.recordId,
        input.channelId,
        input.slotId,
        input.selectedComponents
    );
}

export default createObservableDataAction({
    action: <IAction<ProductComponent[]>>getFilteredSlotComponentsAction
});
