import * as React from 'react';
import { IIngredientSubstitute } from '../../interfaces';
import IngredientSubstituteItem from './ingredient-substitute-item';

export interface IIngredientSubstituteListProps {
    substitutes: IIngredientSubstitute[];
}

export interface IIngredientSubstituteListDispatchProps {
    substituteCheck?(payload: IIngredientSubstitute): void;
}

type Props = IIngredientSubstituteListProps & IIngredientSubstituteListDispatchProps;
const IngredientSubstituteList: React.FC<Props> = (props): JSX.Element => {
    const _renderSubstitues = () => {
        return props.substitutes.map((substitute: IIngredientSubstitute, index: number) => (
            <IngredientSubstituteItem key={index.toString()} item={substitute} check={props.substituteCheck} />
        ));
    };

    return <div className='mx-auto substitute-container'>{_renderSubstitues()}</div>;
};

export default IngredientSubstituteList;
