import * as React from 'react';
import { IIngredientData, IIngredientSubGroups, IModifierLine } from '../../interfaces';
import { ILoadIngredientsPayload } from '../action-creators';
import IngredientsList from '../containers/ingredient-list';
import IngredientSubstituteBox from '../containers/ingredient-substitute-box';
import PizzaSections from '../containers/pizza-sections';
import IngredientsBoxHeader from './ingredients-box-header';

interface IOwnProps {
    title: string;
    ingredients: IIngredientData[];
    ingredientSubGroups?: IIngredientSubGroups;
    allowLeftRight?: boolean;
    modifierLine?: IModifierLine[];
}

interface IStateProps {
    substitue: boolean;
}

interface ICustomizeIngredientsBoxDispatchProps {
    loadIngredients?(payload: ILoadIngredientsPayload): void;
}

interface IProps extends IStateProps, ICustomizeIngredientsBoxDispatchProps, IOwnProps {}

const CustomizeIngredientsBox: React.FC<IProps> = (props: IProps): JSX.Element => {
    React.useEffect(() => {
        props.loadIngredients &&
            props.loadIngredients({
                title: props.title,
                ingredient: props.ingredients,
                ingredientSubGroups: props.ingredientSubGroups,
                allowLeftRight: props.allowLeftRight,
                modifierLine: props.modifierLine
            });
    }, [props.modifierLine]);

    return (
        <div className='as-modifiers-container customize-ingredients-box' style={{ position: 'relative' }}>
            {props.ingredients && props.ingredients.length > 0 && (
                <IngredientsBoxHeader>
                    <div>
                        <h5 className='ms-buybox__customize-ingredients-heading'>Customize: {props.title}</h5>
                    </div>
                    <PizzaSections />
                </IngredientsBoxHeader>
            )}
            <IngredientsList />
            {props.substitue && <IngredientSubstituteBox />}
        </div>
    );
};

export default CustomizeIngredientsBox;
