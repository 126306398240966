import { buildCacheKey } from '@msdyn365-commerce-modules/retail-actions';
import {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    ICommerceApiSettings
} from '@msdyn365-commerce/core';
import { getByIdsAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { SimpleProduct } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';

/**
 * Input class for GetProductByIds data action
 */
export class GetProductByIdsExtInput implements IActionInput {
    public productIds: number[];
    public channelId: number;
    private apiSettings: ICommerceApiSettings;

    constructor(productIds: number[], channelId: number, apiSettings: ICommerceApiSettings) {
        this.productIds = productIds;
        this.channelId = channelId;
        this.apiSettings = apiSettings;
    }

    public getCacheKey = () => buildCacheKey(`SimpleProduct`, this.apiSettings);
    public getCacheObjectType = () => 'SimpleProduct';
    public dataCacheType = (): CacheType => 'request';
}

export async function getProductByIdsAction(input: GetProductByIdsExtInput, ctx: IActionContext): Promise<SimpleProduct[]> {
    return getByIdsAsync({ callerContext: ctx, queryResultSettings: {} }, input.channelId, input.productIds);
}

export default createObservableDataAction({
    action: <IAction<SimpleProduct[]>>getProductByIdsAction
});
