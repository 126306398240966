import { IActionContext } from '@msdyn365-commerce/core';
import { addCartLinesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';
import {
    Address,
    AttributeValueBase,
    Cart,
    CartLine,
    CommerceProperty,
    OrgUnitLocation,
    SimpleProduct
} from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import ExtensionPropReaderHelper from '../lifestyle-helper-classes/extension-prop-reader';
import { getCartState, ICartActionResultWithCart, ICartActionSubStatus, ICartState } from '@msdyn365-commerce/global-state';

export interface ILifestyleCartActionResultWithCart extends ICartActionResultWithCart {
    cartLineNumber?: number;
}

export default async function lifestyleAddProductToCart(
    ctx: IActionContext,
    input: {
        product: SimpleProduct;
        count?: number;
        location?: OrgUnitLocation;
        address?: Address;
        additionalProperties?: object;
        doNotRefreshCartState?: boolean;
        copyExtensionProps?: string[];
    }
): Promise<ILifestyleCartActionResultWithCart> {
    const cartState = await getCartState(ctx);
    let attributeValues: AttributeValueBase[] = [];
    let extensionProperties: CommerceProperty[] | undefined;

    if (
        input.copyExtensionProps &&
        input.copyExtensionProps.length &&
        input.product &&
        input.product.ExtensionProperties &&
        input.product.ExtensionProperties.length
    ) {
        extensionProperties = input.product.ExtensionProperties.filter(
            (extensionProps: CommerceProperty, index: number) =>
                extensionProps.Key && input.copyExtensionProps?.includes(extensionProps.Key)
        );

        // copy product configurator extension properties to attribute values on cart line item because there was a bug where properties were not sending via extension properties.  We have to send it via attribute values instead
        attributeValues = ExtensionPropReaderHelper.copyPCExtensionPropertiesToAttributeValues(extensionProperties);
    }

    const cartLine: CartLine = {
        CatalogId: ctx.requestContext.apiSettings.catalogId,
        Description: input.product.Description,
        EntryMethodTypeValue: 3,
        ItemId: input.product.ItemId,
        ProductId: input.product.RecordId,
        Quantity: input.count || 1,
        TrackingId: '',
        UnitOfMeasureSymbol: input.product.DefaultUnitOfMeasure,
        ExtensionProperties: [],
        AttributeValues: attributeValues
    };

    if (input.location) {
        if (!ctx.requestContext.channel) {
            return { cart: undefined, status: 'FAILED' };
        }

        cartLine.DeliveryMode = ctx.requestContext.channel.PickupDeliveryModeCode;
        cartLine.FulfillmentStoreId = input.location.InventoryLocationId;
        cartLine.ShippingAddress = _buildAddressFromOrgUnitLocation(input.location);
    } else if (input.address) {
        cartLine.ShippingAddress = input.address;
    }

    return _doCartOperationWithRetry(
        () => lifestyleAddProductToCartInternal(cartState.cart, cartLine, ctx),
        cartState,
        input.doNotRefreshCartState
    );
}

async function lifestyleAddProductToCartInternal(
    cart: Readonly<Cart>,
    cartLineToAdd: CartLine,
    actionContext: IActionContext
): Promise<ILifestyleCartActionResultWithCart> {
    if (!cart.CartLines) {
        return { cart: undefined, status: 'FAILED' };
    }

    const quantityLimit: number = actionContext.requestContext.app.config.maxQuantityForCartLineItem || 10;

    const totalQuantity = cart.CartLines.filter(
        (cartLine: CartLine) =>
            cartLine.ProductId === cartLineToAdd.ProductId &&
            (cartLine.DeliveryMode || '') === (cartLineToAdd.DeliveryMode || '') &&
            (cartLine.FulfillmentStoreId || '') === (cartLineToAdd.FulfillmentStoreId || '')
    ).length;

    if ((totalQuantity || 0) >= quantityLimit) {
        return { cart: undefined, status: 'FAILED', substatus: 'MAXQUANTITY' };
    }

    const newCartLine = { ...cartLineToAdd };

    newCartLine.Quantity = Math.min(cartLineToAdd.Quantity || 1, quantityLimit);

    if (cart.Version) {
        return addCartLinesAsync({ callerContext: actionContext }, cart.Id, [newCartLine], cart.Version)
            .then(newCart => {
                return <ICartActionResultWithCart>{ cart: newCart, status: 'SUCCESS' };
            })
            .catch(error => {
                actionContext.telemetry.trace(error);
                actionContext.telemetry.trace('Unable to add Cart Line');

                return <ICartActionResultWithCart>{ cart: undefined, status: 'FAILED' };
            });
    } else {
        actionContext.telemetry.warning('Unable to update Cart Line, Cart Version could not be found');
    }

    return <ICartActionResultWithCart>{ cart: undefined, status: 'FAILED' };
}

function _buildAddressFromOrgUnitLocation(location: OrgUnitLocation): Address {
    return {
        RecordId: location.PostalAddressId,
        Name: location.OrgUnitName,
        FullAddress: location.Address,
        Street: location.State,
        StreetNumber: location.StreetNumber,
        City: location.City,
        DistrictName: location.DistrictName,
        BuildingCompliment: location.BuildingCompliment,
        Postbox: location.Postbox,
        ThreeLetterISORegionName: location.Country,
        ZipCode: location.Zip,
        County: location.County,
        CountyName: location.CountyName,
        State: location.State,
        StateName: location.StateName
    };
}

async function _doCartOperationWithRetry(
    callback: () => Promise<ICartActionResultWithCart>,
    cartState: ICartState,
    doNotRefreshCartState?: boolean
): Promise<ILifestyleCartActionResultWithCart> {
    const currentCartLineNumbers = cartState.cart.CartLines?.map((item: CartLine, index: number) => item.LineNumber);
    let cartLineNumber: number | undefined;
    let callbackResult = await callback();

    if (callbackResult.status !== 'SUCCESS' && !_shouldRetrySubstatus(callbackResult.substatus)) {
        const refreshCartResult = await cartState.refreshCart({});

        if (refreshCartResult.status === 'SUCCESS') {
            callbackResult = await callback();
        }
    }

    if (callbackResult.cart && callbackResult.cart.CartLines) {
        const newCartLineNumbers = callbackResult.cart.CartLines?.map((item: CartLine, index: number) => item.LineNumber);
        const additionalLineNumbers = newCartLineNumbers?.filter(
            (lineNumber: number | undefined, index: number) => !currentCartLineNumbers || !currentCartLineNumbers?.includes(lineNumber)
        );
        if (additionalLineNumbers && additionalLineNumbers.length && additionalLineNumbers.length > 0) {
            cartLineNumber = additionalLineNumbers[0];
        }
    }

    if (!doNotRefreshCartState) {
        await cartState.refreshCart({});
    }

    return {
        cart: cartState.cart,
        status: callbackResult.status,
        substatus: callbackResult.substatus,
        cartLineNumber
    };
}

function _shouldRetrySubstatus(substatus?: ICartActionSubStatus): boolean {
    if (!substatus) {
        return true;
    }

    // all substatus currently don't result in a retry
    return false;
}
