import { buildCacheKey } from '@msdyn365-commerce-modules/retail-actions';
import {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    ICommerceApiSettings
} from '@msdyn365-commerce/core';
import { assignValueAsync } from './DataActionExtension.g';
import { IProductConfiguration } from './DataServiceEntities.g';

/**
 * Input class for Assign Product Configuration data action
 */
export class AssignProductConfigurationExtInput implements IActionInput {
    public modelXml: string;
    public solverStrategy: number;
    public cartLineId: string;
    public instanceId: number;
    public value: string;
    private apiSettings: ICommerceApiSettings;

    constructor(
        modelXml: string,
        solverStrategy: number,
        cartLineId: string,
        instanceId: number,
        value: string,
        apiSettings: ICommerceApiSettings
    ) {
        this.modelXml = modelXml;
        this.solverStrategy = solverStrategy;
        this.cartLineId = cartLineId;
        this.instanceId = instanceId;
        this.value = value;
        this.apiSettings = apiSettings;
    }

    public getCacheKey = () => buildCacheKey(`ProductConfigurationExtensionClass`, this.apiSettings);
    public getCacheObjectType = () => 'ProductConfigurationExtensionClass';
    public dataCacheType = (): CacheType => 'request';
}

export async function assignProductConfigurationAction(
    input: AssignProductConfigurationExtInput,
    ctx: IActionContext
): Promise<IProductConfiguration> {
    return assignValueAsync(
        { callerContext: ctx, queryResultSettings: {} },
        input.modelXml,
        input.solverStrategy,
        input.cartLineId,
        input.instanceId,
        input.value
    );
}

export default createObservableDataAction({
    action: <IAction<IProductConfiguration>>assignProductConfigurationAction
});
