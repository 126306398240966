import { connect } from 'react-redux';

import { IState } from '../../interfaces';
import { loadIngredients } from '../action-creators';
import CustomizeIngredientsBox from '../components/customize-ingredients-box';

const mapStateToProps = (state: IState) => {
    const hasAnySubstituteAvailable = !!(state.customizeIngredients.substitutes && state.customizeIngredients.substitutes.length > 0);
    return {
        substitue: hasAnySubstituteAvailable
    };
};

const mapDispatchToProps = {
    loadIngredients
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomizeIngredientsBox);
