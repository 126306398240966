import { IAddToCartFailureResult } from '@msdyn365-commerce/components';
import { IAny, ICoreContext, IGeneric } from '@msdyn365-commerce/core';
import { ICartState } from '@msdyn365-commerce/global-state';
import { OrgUnitLocation, ProductAvailableQuantity, ProductDimension, SimpleProduct } from '@msdyn365-commerce/retail-proxy';
import { IBuyboxExtResources } from '../buybox-ext.props.autogenerated';

export function getQuantityError(stockLeft: number | undefined, resources: IBuyboxExtResources): string | undefined {
    if (!stockLeft || stockLeft <= 0) {
        return resources.errorMessageOutOfStock;
    } else if (stockLeft === 1) {
        return resources.errorMessageOutOfRangeOneLeft;
    } else {
        return resources.errorMessageOutOfRangeFormat.replace('{numLeft}', stockLeft.toString());
    }
}

// tslint:disable-next-line:cyclomatic-complexity
export function getGenericError(
    result: IAddToCartFailureResult,
    cart: ICartState | undefined,
    resources: IBuyboxExtResources,
    context: ICoreContext<IGeneric<IAny>>,
    product: SimpleProduct | undefined,
    productAvailability: ProductAvailableQuantity | undefined,
    location: OrgUnitLocation | undefined
): string | undefined {
    if (result.failureReason === 'EMPTYINPUT') {
        return resources.addedToCartFailureMessage;
    } else if (result.failureReason === 'CARTACTIONFAILED') {
        if (result.cartActionResult && result.cartActionResult.substatus === 'MAXQUANTITY') {
            // get the quantity of the product in cart
            let elementFoundAt: number = -1;
            const productIdToFind = product?.RecordId;

            for (let i = 0; i < cart!.cart.CartLines!.length; i++) {
                if (
                    cart!.cart.CartLines![i].ProductId === productIdToFind &&
                    (cart!.cart.CartLines![i].DeliveryMode || '') ===
                        ((location && context.actionContext.requestContext.channel?.PickupDeliveryModeCode) || '') &&
                    (cart!.cart.CartLines![i].FulfillmentStoreId || '') === (location?.OrgUnitNumber || '')
                ) {
                    elementFoundAt = i;

                    break;
                }
            }

            let curQuantity: number = 0;

            if (elementFoundAt !== -1) {
                const cartLineToUpdate = { ...cart!.cart.CartLines![elementFoundAt] };
                curQuantity = cartLineToUpdate.Quantity || 0;
            }

            // get the quantity limit for the product
            const maxQuantityForCartLineItem = context.app.config.maxQuantityForCartLineItem;
            const availableQty = productAvailability?.AvailableQuantity;

            let quantityLimit: number;

            if (maxQuantityForCartLineItem === undefined && availableQty === undefined) {
                quantityLimit = 10;
            } else if (availableQty === undefined) {
                quantityLimit = maxQuantityForCartLineItem;
            } else if (maxQuantityForCartLineItem === undefined) {
                quantityLimit = availableQty;
            } else {
                quantityLimit = Math.min(maxQuantityForCartLineItem, availableQty);
            }

            return resources.maxQuantityLimitText
                .replace('{curQuantity}', curQuantity.toString())
                .replace('{maxQuantity}', quantityLimit.toString());
        }

        return resources.addedToCartFailureMessage;
    }

    return undefined;
}

export function getConfigureErrors(
    result: ProductDimension[] | undefined,
    resources: IBuyboxExtResources
): { [configureId: string]: string | undefined } {
    if (!result) {
        return {};
    }

    const dimensions: { [configureId: string]: string | undefined } = {};

    result.forEach(dimension => {
        dimensions[dimension.DimensionTypeValue] = getDimensionErrorString(dimension.DimensionTypeValue, resources);
    });

    return dimensions;
}

export function getDimensionErrorString(dimensionTypeValue: number, resources: IBuyboxExtResources): string {
    switch (dimensionTypeValue) {
        case 1: // ProductDimensionType.Color
            return resources.productDimensionTypeColorErrorMessage;
        case 2: // ProductDimensionType.Configuration
            return resources.productDimensionTypeConfigurationErrorMessage;
        case 3: // ProductDimensionType.Size
            return resources.productDimensionTypeSizeErrorMessage;
        case 4: // ProductDimensionType.Style
            return resources.productDimensionTypeStyleErrorMessage;
        default:
            return '';
    }
}
