import { Constants } from '../lifestyle-helper-classes/constant';
import { IBuyboxExtData } from './buybox-ext.data';
import { IBuyboxExtProps } from './buybox-ext.props.autogenerated';
import { IModifierLine } from './components/interfaces';
import { getCartState, ICartState } from '@msdyn365-commerce/global-state';
import * as parser from 'fast-xml-parser';

export interface IProductConfiguratorCartLineCurrentValue {
    name: string;
    uniqueId: string;
    value: string;
}

const queryString = 'cartLineNumber';

export const getExtensionPropertiesStringValueByKey = (key: string, props: IBuyboxExtProps<IBuyboxExtData>): string | undefined => {
    const cartLines = props.data.cart.result?.cart.CartLines;

    if (!cartLines || cartLines.length <= 0) {
        return undefined;
    }

    const matchingCartLine = cartLines.filter(cartLine => cartLine.LineNumber === getCartLineNumberFromUrl(props));

    if (matchingCartLine.length <= 0) {
        return undefined;
    }

    const modifierLinesArray = matchingCartLine[0].ExtensionProperties?.filter(item => item.Key === key);

    if (modifierLinesArray && modifierLinesArray.length > 0 && modifierLinesArray[0].Value && modifierLinesArray[0].Value.StringValue) {
        return modifierLinesArray[0].Value.StringValue;
    }

    return undefined;
};

export const getExtensionPropertiesDecimalValueByKey = (key: string, props: IBuyboxExtProps<IBuyboxExtData>): number | undefined => {
    const cartLines = props.data.cart.result?.cart.CartLines;

    if (!cartLines || cartLines.length <= 0) {
        return undefined;
    }

    const matchingCartLine = cartLines.filter(cartLine => cartLine.LineNumber === getCartLineNumberFromUrl(props));

    if (matchingCartLine.length <= 0) {
        return undefined;
    }

    const modifierLinesArray = matchingCartLine[0].ExtensionProperties?.filter(item => item.Key === key);

    if (modifierLinesArray && modifierLinesArray.length > 0 && modifierLinesArray[0].Value && modifierLinesArray[0].Value.DecimalValue) {
        return modifierLinesArray[0].Value.DecimalValue;
    }

    return undefined;
};

export const getCartLineNumberFromUrl = (props: IBuyboxExtProps<IBuyboxExtData>): number | undefined =>
    Number(props.context.request.query && props.context.request.query[queryString]);

export const getCartLineQuantity = async (props: IBuyboxExtProps<IBuyboxExtData>, cartLineNumber?: number): Promise<number | undefined> => {
    const cartState: ICartState = await getCartState(props.context.actionContext);

    const cartLines = cartState.cart.CartLines;
    if (cartLines && cartLines.length > 0) {
        const matchingCartLine = cartLines.filter(cartLine => cartLine.LineNumber === cartLineNumber);
        if (matchingCartLine.length > 0) {
            return matchingCartLine[0].Quantity;
        }
    }

    return undefined;
};

export const getModifierLinesFromExtensionProperties = (props: IBuyboxExtProps<IBuyboxExtData>): IModifierLine[] | undefined => {
    const { data } = props;
    const cartLines = data.cart.result?.cart.CartLines;

    if (cartLines && cartLines.length > 0) {
        const matchingCartLine = cartLines.filter(cartLine => cartLine.LineNumber === getCartLineNumberFromUrl(props));
        if (matchingCartLine.length > 0) {
            const modifierLinesArray = matchingCartLine[0].ExtensionProperties?.filter(item => item.Key === 'AS_ModifierLines');
            if (
                modifierLinesArray &&
                modifierLinesArray.length > 0 &&
                modifierLinesArray[0].Value &&
                modifierLinesArray[0].Value.StringValue
            ) {
                return JSON.parse(modifierLinesArray[0].Value.StringValue);
            }
        }
    }
    return undefined;
};

export const getProductConfiguratorCurrentValuesXml = (props: IBuyboxExtProps<IBuyboxExtData>): string | undefined => {
    const cartLines = props.data.cart.result?.cart.CartLines;

    if (!cartLines || cartLines.length <= 0) {
        return undefined;
    }

    const matchingCartLine = cartLines.filter(cartLine => cartLine.LineNumber === getCartLineNumberFromUrl(props));

    if (matchingCartLine.length <= 0) {
        return undefined;
    }

    const modifierLinesArray = matchingCartLine[0].ExtensionProperties?.filter(item => item.Key === Constants.PCCURRENTVALUES_EXT);
    if (modifierLinesArray && modifierLinesArray.length > 0 && modifierLinesArray[0].Value && modifierLinesArray[0].Value.StringValue) {
        return modifierLinesArray[0].Value.StringValue;
    }

    return undefined;
};

export const getProductConfiguratorCurrentValues = (
    props: IBuyboxExtProps<IBuyboxExtData>
): IProductConfiguratorCartLineCurrentValue[] | undefined => {
    const cartLines = props.data.cart.result?.cart.CartLines;

    if (!cartLines || cartLines.length <= 0) {
        return undefined;
    }

    const matchingCartLine = cartLines.filter(cartLine => cartLine.LineNumber === getCartLineNumberFromUrl(props));

    if (matchingCartLine.length <= 0) {
        return undefined;
    }

    const modifierLinesArray = matchingCartLine[0].ExtensionProperties?.filter(item => item.Key === Constants.PCCURRENTVALUES_EXT);
    if (modifierLinesArray && modifierLinesArray.length > 0 && modifierLinesArray[0].Value && modifierLinesArray[0].Value.StringValue) {
        const options = {
            attributeNamePrefix: '_',
            attrNodeName: 'attr', // default is 'false'
            textNodeName: '#text',
            ignoreAttributes: false,
            ignoreNameSpace: false,
            allowBooleanAttributes: false,
            parseNodeValue: true,
            parseAttributeValue: true,
            trimValues: true,
            cdataTagName: '__cdata', // default is 'false'
            cdataPositionChar: '\\c',
            parseTrueNumberOnly: false,
            arrayMode: false, // "strict"
            stopNodes: ['parse-me-as-string']
        };
        const tObj = parser.getTraversalObj(modifierLinesArray[0].Value.StringValue, options);
        const jsonObj = parser.convertToJson(tObj, options);

        if (
            jsonObj &&
            jsonObj.Session &&
            jsonObj.Session.Component &&
            jsonObj.Session.Component.Attribute &&
            jsonObj.Session.Component.Attribute.length > 0
        ) {
            return jsonObj.Session.Component.Attribute.map(
                // tslint:disable-next-line
                (x: any) =>
                    <IProductConfiguratorCartLineCurrentValue>{ name: x.attr._name, uniqueId: x.attr._uniqueId, value: x.attr._value }
            );
        }
    }

    return undefined;
};
