import { connect } from 'react-redux';
import {
    EditabilityText,
    IIngredientSubstitute,
    IIngredientWithSections,
    IModifierLine,
    IModifierLineRequest,
    IModifierLocal,
    IState,
    LeftRightSetting,
    LeftRightWhole,
    ModifierGroupType,
    ModifierLineAction,
    ModifierLineType
} from './components/interfaces';
import LifestyleAddToCart from './lifestyle-add-to-cart.component';

// tslint:disable-next-line:max-func-body-length
const mapStateToProps = (state: IState) => {
    const modifierLineRequest: IModifierLineRequest[] = [];
    const leftRightHeldIngredients: IModifierLineRequest[] = getLeftRightRequest(state);

    const Ingredients: IModifierLineRequest[] = getTextEditableIngredientRequest(state);

    const removedIngredient: IModifierLineRequest[] = state.customizeIngredients.wholeModifiersArray.map(
        (item: IIngredientWithSections) => {
            return {
                Type: ModifierLineType.Ingredient,
                ProductId: item.ProductRecId,
                ParentProductId: item.ProductId,
                ItemId: item.ItemId,
                LineNum: item.LineNum,
                LeftRight: item.LeftRight || 0,
                Units: 0,
                Name: item.Name
            };
        }
    );

    const modifier: IModifierLineRequest[] = state.customizeModifiers.modifierGroups
        .reduce((accumulated: IModifierLocal[], current) => {
            return [...accumulated, ...current.currentModifiers.filter((item: IModifierLocal) => item.quantity > 0)];
        }, [])
        .map((item: IModifierLocal) => {
            return createModifierLineRequest(item);
        });

    let productId: number = 0;
    // Find out items that are substituted, if NOT 0, which means item is substituted
    // Get the values from ingredient Subgroup Array using subGroupId and substitutionLineNum
    const substitution: IModifierLineRequest[] = state.customizeIngredients.ingredientsForDifferentSections
        .filter((item: IIngredientWithSections) => item.substitutionLineNum !== 0)
        .reduce((accumulated: IIngredientSubstitute[], current: IIngredientWithSections) => {
            productId = current.ProductId;
            return [
                ...accumulated,
                ...((state.customizeIngredients.ingredientSubGroups &&
                    state.customizeIngredients.ingredientSubGroups[current.SubGroupId]
                        .filter((item: IIngredientSubstitute) => item.LineNum === current.substitutionLineNum)
                        .map(ingredientSubstitute => ({
                            ...ingredientSubstitute,
                            LineNum: current.itemLineNum
                        }))) ||
                    [])
            ];
        }, [])
        .map((item: IIngredientSubstitute) => {
            return {
                Type: ModifierLineType.Ingredient,
                ItemId: item.ItemId,
                LineNum: item.LineNum,
                LeftRight: 0,
                Units: item.QuantityUnit,
                Name: item.Name,
                ProductId: item.ProductRecId,
                ParentProductId: productId === 0 ? undefined : productId
            };
        });

    modifierLineRequest.push(...leftRightHeldIngredients, ...Ingredients, ...removedIngredient, ...modifier, ...substitution);
    return {
        modifierLineRequest
    };
};

// first check if allow left right is true/false
// second if false, filter out for whole and anything that is not normal editablity

// if left right is true just filter out for not normal editablity

const getTextEditableIngredientRequest = (state: IState) => {
    const ingredientsForDifferentSections = state.customizeIngredients.ingredientsForDifferentSections;
    const modifierLines = state.customizeIngredients.modifierLine;
    const isLeftRight = state.customizeIngredients.allowLeftRight;
    return filterAndCreateRequest(ingredientsForDifferentSections, modifierLines, isLeftRight);
};

const filterAndCreateRequest = (items: IIngredientWithSections[], modifierLines: IModifierLine[] | undefined, leftRight?: boolean) => {
    return items
        .filter((item: IIngredientWithSections) => (leftRight ? true : filterByWhole(item)))
        .filter((item: IIngredientWithSections) => {
            // determine left right whole for current item
            let modifierLeftRightWholeSetting = LeftRightSetting.Whole;

            switch (item.section) {
                case LeftRightWhole.left:
                    modifierLeftRightWholeSetting = LeftRightSetting.Left;
                    break;
                case LeftRightWhole.right:
                    modifierLeftRightWholeSetting = LeftRightSetting.Right;
                    break;
                case LeftRightWhole.whole:
                    modifierLeftRightWholeSetting = LeftRightSetting.Whole;
                    break;
                default:
                    throw new Error('Missing or unrecognized left right whole enum value from item.');
            }

            // filter by not normal condition if there is no item matching modifier line; otherwise, don't filter and allow normal condition as a modifier (update cart scenario)
            if (
                modifierLines &&
                modifierLines.find(x => x.ProductId === item.ProductRecId && x.LeftRight === modifierLeftRightWholeSetting)
            ) {
                return true;
            }

            return item.editabilityOption !== EditabilityText.Normal;
        })
        .map((item: IIngredientWithSections) => {
            return {
                Type: ModifierLineType.Ingredient,
                ProductId: item.ProductRecId,
                ParentProductId: item.ProductId,
                ItemId: item.ItemId,
                LineNum: item.LineNum,
                LeftRight: item.LeftRight || 0,
                Units: calculateQuantity(item),
                Name: item.Name
            };
        });
};

const filterByWhole = (item: IIngredientWithSections) => item.section === LeftRightWhole.whole;

const calculateQuantity = (item: IIngredientWithSections) => {
    switch (item.editabilityOption) {
        case EditabilityText.Normal:
            return item.Units;
        case EditabilityText.Extra:
            return item.Units + 0.5;
        case EditabilityText.Light:
            return item.Units - 0.5;
        default:
    }
    return item.Units;
};

const getLeftRightRequest = (state: IState) => {
    return state.customizeIngredients.allowLeftRight ? createLeftRightRequest(state.customizeIngredients.leftRightModifiersArray) : [];
};

const createLeftRightRequest = (ingredients: IIngredientWithSections[]) => {
    return ingredients.map((item: IIngredientWithSections) => {
        return {
            Type: ModifierLineType.Ingredient,
            ProductId: item.ProductRecId,
            ParentProductId: item.ProductId,
            ItemId: item.ItemId,
            LineNum: item.LineNum,
            LeftRight: item.LeftRight || 0,
            Units: 0,
            Name: item.Name,
            UoMSymbol: item.UOM,
            Price: item.ProductId
        };
    });
};

const createModifierLineRequest = (item: IModifierLocal) => {
    switch (item.GroupRef?.Type) {
        case ModifierGroupType.Product:
            return createIngredientRequest(item);
        case ModifierGroupType.Recipe:
            return createModifierRequest(item);
        case ModifierGroupType.Substitutions:
            return createComponentRequest(item);
        case ModifierGroupType.Text:
            return createTextModifierRequest(item);
        default:
    }
    return createModifierRequest(item);
};

const createModifierRequest = (item: IModifierLocal): IModifierLineRequest => {
    return {
        Type: item.modifierLineType,
        ItemId: item.ItemId,
        LeftRight: item.section === LeftRightWhole.left ? 1 : item.section === LeftRightWhole.right ? 2 : 0,
        Units: item.quantity,
        Name: item.Name,
        ProductId: item.ProductRecId,
        ParentProductId: item.ProductId,
        ModGroupId: item.modifierGroupId,
        UoMSymbol: item.UOM,
        ProductRecId: item.ProductRecId
    };
};

const createTextModifierRequest = (item: IModifierLocal): IModifierLineRequest => {
    return {
        Type: item.modifierLineType,
        Action: ModifierLineAction.None,
        ParentProductId: item.ProductId,
        LineNum: item.LineNum,
        ModGroupId: item.modifierGroupId,
        LeftRight: item.section === LeftRightWhole.left ? 1 : item.section === LeftRightWhole.right ? 2 : 0,
        Units: item.quantity,
        Name: item.Name,
        UoMSymbol: '',
        Price: item.Price
    };
};

const createComponentRequest = (item: IModifierLocal): IModifierLineRequest => {
    // need slot id
    return {
        Type: item.modifierLineType,
        Action: ModifierLineAction.Substitute,
        ProductId: item.ProductRecId,
        LeftRight: item.section === LeftRightWhole.left ? 1 : item.section === LeftRightWhole.right ? 2 : 0,
        Units: item.quantity,
        Name: item.Name,
        UoMSymbol: item.UOM,
        Price: item.Price
    };
};

const createIngredientRequest = (item: IModifierLocal): IModifierLineRequest => {
    return {
        Type: item.modifierLineType,
        Action: ModifierLineAction.Increase,
        ProductId: item.ProductRecId,
        ParentProductId: item.ProductId,
        ItemId: item.ItemId,
        LineNum: item.LineNum,
        LeftRight: item.section === LeftRightWhole.left ? 1 : item.section === LeftRightWhole.right ? 2 : 0,
        Units: item.quantity,
        Name: item.Name,
        UoMSymbol: item.UOM,
        Price: item.Price
    };
};

export default connect(mapStateToProps)(LifestyleAddToCart);
