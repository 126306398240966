import * as React from 'react';
import { IModifierLocal } from '../../interfaces';
import { IModifierEditabilityPayload } from '../action-creators';
import CustomizeModifierImage from './customize-modifier-image';
import ModifiersQuantitySection from './modifiers-quantity-section';

export interface IModifierItemProps {
    modifierItem: IModifierLocal;
    isBelowMaxQuantity(): boolean;
    isAboveMinQuantity(): boolean;
}

export interface IIngredientItemDispatchProps {
    increase?(payload: IModifierEditabilityPayload): void;
    decrease?(payload: IModifierEditabilityPayload): void;
}

type Props = IModifierItemProps & IIngredientItemDispatchProps;
const ModifierItem = (props: Props): JSX.Element => {
    const { modifierItem, increase, decrease, isBelowMaxQuantity, isAboveMinQuantity } = props;

    const _handleIncreaseButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (isBelowMaxQuantity()) {
            increase &&
                increase({
                    modifier: modifierItem,
                    quantity: modifierItem.quantity + 1
                });
        }
    };

    const _handleDecreaseButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (modifierItem.quantity > 0 && isAboveMinQuantity()) {
            decrease &&
                decrease({
                    modifier: modifierItem,
                    quantity: modifierItem.quantity - 1
                });
        }
    };

    return (
        <div className='ingredient-item col-md-4 col-sm-6 col-12'>
            <div className='item-line ingredient-item-line'>
                <CustomizeModifierImage name={modifierItem.Name} image={modifierItem.Image} />
                <ModifiersQuantitySection
                    price={modifierItem.Price}
                    quantity={modifierItem.quantity}
                    enableIncrease
                    enableDecrease
                    increase={_handleIncreaseButtonClick}
                    decrease={_handleDecreaseButtonClick}
                />
            </div>
        </div>
    );
};

export default ModifierItem;
