export enum ActionTypes {
    INITIAL_DISPATCH = 'INITIAL_DISPATCH',
    LOAD_INGREDIENTS = 'LOAD_INGREDIENTS',
    LOAD_RECIPES = 'LOAD_RECIPES',
    LOAD_CONFIGURATOR = 'LOAD_CONFIGURATOR',
    LOAD_SLOTS = 'LOAD_SLOTS',
    PIZZA_SECTION_CLICK = 'PIZZA_SECTION_CLICK',
    ITEM_CLICK = 'ITEM_CLICK',
    ITEM_CHECK = 'ITEM_CHECK',
    RECIPE_CHECK = 'RECIPE_CHECK',
    SLOT_CHECK = 'SLOT_CHECK',
    ATTRIBUTE_CHECK = 'ATTRIBUTE_CHECK',
    DOMAIN_CHECK = 'DOMAIN_CHECK',
    INCREASE_CLICK = 'INCREASE_CLICK',
    DECREASE_CLICK = 'DECREASE_CLICK',
    GET_SUBSTITUES = 'GET_SUBSTITUES',
    CLOSE_SUBSTITUTE_BOX = 'CLOSE_SUBSTITUTE_BOX',
    SUBSTITUTE_CHECK = 'SUBSTITUTE_CHECK',
    MAKE_SUBSTITUTION = 'MAKE_SUBSTITUTION',
    LOAD_MODIFIERS = 'LOAD_MODIFIERS',
    MODIFIER_PIZZA_SECTION_CLICK = 'MODIFIER_PIZZA_SECTION_CLICK',
    MODIFIER_INCREASE_CLICK = 'MODIFIER_INCREASE_CLICK',
    MODIFIER_DECREASE_CLICK = 'MODIFIER_DECREASE_CLICK'
}
