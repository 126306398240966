import classnames from 'classnames';
import * as React from 'react';
import { IProductComponentWithData } from '../../interfaces';
import { ISlotCheckPayload } from '../action-creators';
import SlotCheckBox from './slot-checkbox';

export interface ISlotComponentProps {
    item: IProductComponentWithData;
}

export interface ISlotComponentItemDispatchProps {
    slotCheck?(payload: ISlotCheckPayload): void;
}

type Props = ISlotComponentProps & ISlotComponentItemDispatchProps;
const SlotComponentItem = (props: Props): JSX.Element => {
    const { item, slotCheck } = props;
    const _handleRecipeCheckBoxChange = () => {
        item.checked = true;
        slotCheck && slotCheck({ component: item, checked: true });
    };

    const ingredientClass = classnames('ingredient-item-line', {
        'ingredient-item-line-selected': item.checked
    });

    return (
        <div className='ingredient-item col-md-4 col-sm-6 col-12'>
            <div role='none' className={`item-line ${ingredientClass}`}>
                <SlotCheckBox name={item.slot.Name ? item.slot.Name : ''} checked={item.checked} slotCheck={_handleRecipeCheckBoxChange} />
            </div>
        </div>
    );
};

export default SlotComponentItem;
