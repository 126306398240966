import * as React from 'react';

export interface IModifiersQuantitySectionProps {
    price: number;
    quantity: number;
    enableIncrease?: boolean;
    enableDecrease?: boolean;
    increase?(e: React.MouseEvent<HTMLButtonElement>): void;
    decrease?(e: React.MouseEvent<HTMLButtonElement>): void;
}

const IngredientEditabilityButton = (props: IModifiersQuantitySectionProps): JSX.Element => {
    const { price, quantity, enableIncrease, enableDecrease, increase, decrease } = props;
    let modification: string = '';
    if (price > 0 && quantity > 0) {
        modification = `X  ${quantity} [ $${(price * quantity).toFixed(2)} ]`;
    } else {
        modification = quantity.toString();
    }
    return (
        <a className='item-row item-quantity ms-refine-submenu-item-row center-align'>
            <button disabled={!enableDecrease} className='decrement quantity__controls ingredientcontrol accent' onClick={decrease}>
                <span className='fas fa-minus quantity__controls-glyph' />
            </button>
            <div className='item-label item-edit-label ingredients-edit-label'>
                <label className='quantity-input'>{modification}</label>
            </div>
            <button disabled={!enableIncrease} className='increment quantity__controls ingredientcontrol accent' onClick={increase}>
                <span className='fas fa-plus quantity__controls-glyph' />
            </button>
        </a>
    );
};

export default IngredientEditabilityButton;
