import { ActionTypes } from '../../actions-types';
import { ILoadRecipePayload } from '../../customize-recipe/action-creators';
import { IProductComponentWithData, IRecipeWithData } from '../../interfaces';

export interface ILoadComponentsPayload {
    components: IProductComponentWithData[];
    onSlotchange?(payload: IProductComponentWithData[] | null, selectedIndex: number): void;
}

export interface ILoadComponents {
    type: ActionTypes.LOAD_SLOTS;
    payload: ILoadComponentsPayload;
}

export const loadSlotComponents = (payload: ILoadComponentsPayload): ILoadComponents => ({
    type: ActionTypes.LOAD_SLOTS,
    payload
});

export interface ISlotCheckPayload {
    component: IProductComponentWithData;
    checked: boolean;
}

export interface ISlotCheck {
    type: ActionTypes.SLOT_CHECK;
    payload: ISlotCheckPayload;
}

export interface IItemCheckPayload {
    recipe: IRecipeWithData;
    checked: boolean;
}

export interface IItemCheck {
    type: ActionTypes.RECIPE_CHECK;
    payload: IItemCheckPayload;
}

export const itemCheck = (payload: IItemCheckPayload): IItemCheck => ({
    type: ActionTypes.RECIPE_CHECK,
    payload
});

export const slotCheck = (payload: ISlotCheckPayload): ISlotCheck => ({
    type: ActionTypes.SLOT_CHECK,
    payload
});

export interface ILoadRecipe {
    type: ActionTypes.LOAD_RECIPES;
    payload: ILoadRecipePayload;
}

export type CustomizeRecipeActions = ILoadComponents | ISlotCheck | IItemCheck | ILoadRecipe;
