import { ActionTypes } from '../../actions-types';
import { IModifierData, IModifierGroup, IModifierLine, IModifierLocal, LeftRightWhole } from '../../interfaces';

export interface ILoadModifiersPayload {
    modifiers: IModifierGroup[];
    modifierLine?: IModifierLine[];
}

export interface ILoadModifiers {
    type: ActionTypes.LOAD_MODIFIERS;
    payload: ILoadModifiersPayload;
}

export interface IModifiersCheckPayload {
    modifier: IModifierData;
    checked: boolean;
}

export const loadModifiers = (payload: ILoadModifiersPayload): ILoadModifiers => ({
    type: ActionTypes.LOAD_MODIFIERS,
    payload
});

export interface IModifierPizzaSectionPayload {
    section: LeftRightWhole;
    modifierGroupId: number;
}

export interface IModifierPizzaSectionClickAction {
    type: ActionTypes.MODIFIER_PIZZA_SECTION_CLICK;
    payload: IModifierPizzaSectionPayload;
}

export const pizzaSectionClick = (payload: IModifierPizzaSectionPayload): IModifierPizzaSectionClickAction => ({
    type: ActionTypes.MODIFIER_PIZZA_SECTION_CLICK,
    payload
});

export interface IModifierEditabilityPayload {
    modifier: IModifierLocal;
    quantity: number;
}

export interface IModifierIncreaseClick {
    type: ActionTypes.MODIFIER_INCREASE_CLICK;
    payload: IModifierEditabilityPayload;
}

export const increaseClick = (payload: IModifierEditabilityPayload): IModifierIncreaseClick => ({
    type: ActionTypes.MODIFIER_INCREASE_CLICK,
    payload
});

export interface IModifierDecreaseClick {
    type: ActionTypes.MODIFIER_DECREASE_CLICK;
    payload: IModifierEditabilityPayload;
}

export const decreaseClick = (payload: IModifierEditabilityPayload): IModifierDecreaseClick => ({
    type: ActionTypes.MODIFIER_DECREASE_CLICK,
    payload
});

export type CustomizeModifiersActions = ILoadModifiers | IModifierPizzaSectionClickAction | IModifierIncreaseClick | IModifierDecreaseClick;
