import { ActionTypes } from '../../actions-types';
import { ILoadComponents } from '../../customize-slotComponent/action-creators';
import { IModifierLine, IProductComponentWithData, IRecipeWithData } from '../../interfaces';

export interface ILoadRecipePayload {
    title: React.ReactNode;
    recipes: IRecipeWithData[];
    onchange?(payload: IRecipeWithData[] | null, selectedIndex: number, modifierLines: IModifierLine[] | null): void;
}

export interface ILoadRecipe {
    type: ActionTypes.LOAD_RECIPES;
    payload: ILoadRecipePayload;
}

export const loadRecipe = (payload: ILoadRecipePayload): ILoadRecipe => ({
    type: ActionTypes.LOAD_RECIPES,
    payload
});

export interface IItemCheckPayload {
    recipe: IRecipeWithData;
    checked: boolean;
}

export interface ISlotCheckPayload {
    component: IProductComponentWithData;
    recipe: IRecipeWithData;
    checked: boolean;
}

export interface IItemCheck {
    type: ActionTypes.RECIPE_CHECK;
    payload: IItemCheckPayload;
}

export interface ISlotCheck {
    type: ActionTypes.SLOT_CHECK;
    payload: ISlotCheckPayload;
}

export const itemCheck = (payload: IItemCheckPayload): IItemCheck => ({
    type: ActionTypes.RECIPE_CHECK,
    payload
});

export const slotCheck = (payload: ISlotCheckPayload): ISlotCheck => ({
    type: ActionTypes.SLOT_CHECK,
    payload
});

export type CustomizeRecipeActions = ILoadRecipe | IItemCheck | ISlotCheck | ILoadComponents;
