import { ActionTypes } from '../../actions-types';
import { ICustomizeIngredients, LeftRightWhole } from '../../interfaces';
import { CustomizeIngredientsActions } from '../action-creators';
import {
    closeSubstituteBox,
    getSubstitutes,
    increaseDecreaseClick,
    itemCheck,
    loadIngredients,
    makeSubstitution
} from './customize-ingredients-reducer-helper';

const initialState: ICustomizeIngredients = {
    defaultIngredients: [],
    leftRightModifiersArray: [],
    wholeModifiersArray: [],
    ingredientsForDifferentSections: [],
    leftRightWhole: LeftRightWhole.whole,
    ingredientSubGroups: [],
    substitutes: []
};

export default (state = initialState, action: CustomizeIngredientsActions): ICustomizeIngredients => {
    switch (action.type) {
        case ActionTypes.LOAD_INGREDIENTS:
            return loadIngredients(state, action.payload);
        case ActionTypes.PIZZA_SECTION_CLICK:
            return {
                ...state,
                leftRightWhole: action.payload
            };
        case ActionTypes.ITEM_CHECK:
            return itemCheck(state, action.payload);
        case ActionTypes.INCREASE_CLICK:
        case ActionTypes.DECREASE_CLICK:
            return increaseDecreaseClick(state, action.payload);
        case ActionTypes.GET_SUBSTITUES:
            return getSubstitutes(state, action.payload);
        case ActionTypes.CLOSE_SUBSTITUTE_BOX:
            return closeSubstituteBox(state);
        case ActionTypes.MAKE_SUBSTITUTION:
            return makeSubstitution(state, action.payload);
        default:
            return state;
    }
};
