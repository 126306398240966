import { ActionTypes } from '../../actions-types';
import { IComponentWithData } from '../../interfaces';
import { CustomizeRecipeActions } from '../action-creators';

const initialState: IComponentWithData = { ComponentId: '' };

export default (state = initialState, action: CustomizeRecipeActions): IComponentWithData => {
    switch (action.type) {
        case ActionTypes.LOAD_CONFIGURATOR:
            return {
                ...state,
                ProductConfiguratorCurrentValues: action.payload.currentValues,
                Components: action.payload.components?.Components
            };
        case ActionTypes.ATTRIBUTE_CHECK:
            const attribute = action.payload.component;
            const attributes: IComponentWithData[] | undefined = state.Components?.filter(item => {
                item.Attributes?.filter(attr => {
                    if (attr === attribute) {
                        attr.checked = true;
                    } else {
                        attr.checked = false;
                    }
                });

                return item;
            });
            return {
                ...state,
                Components: attributes
            };
        case ActionTypes.DOMAIN_CHECK:
            const selectedDomain = action.payload.component;
            action.payload.checked = true;
            const domainAttributes: IComponentWithData[] | undefined = state.Components?.filter(item => {
                item.Attributes?.filter(attr => {
                    attr.Domain?.Values?.filter(domain => {
                        if (domain === selectedDomain) {
                            domain.checked = true;
                        } else {
                            domain.checked = false;
                        }
                    });
                });
                return item;
            });
            return {
                ...state,
                Components: domainAttributes
            };
        default:
            return state;
    }
};
