import * as React from 'react';
import { IGridSettings, Image } from '@msdyn365-commerce/core';

export interface IConfiguratorCheckBoxBoxProps {
    image?: string;
    fallbackImage?: string;
    gridSettings?: IGridSettings;
    name: string;
    selectedValue?: string;
    checked: boolean;
    check?(): void;
}

const ConfiguratorCheckBox: React.FunctionComponent<IConfiguratorCheckBoxBoxProps> = (props: IConfiguratorCheckBoxBoxProps) => {
    const { name, checked, check, image, fallbackImage, gridSettings, selectedValue } = props;
    let itemTypeClassName = selectedValue && !checked && selectedValue === 'Select' ? 'selection-attr single-select' : 'single-select';
    itemTypeClassName = `ms-refine-configuration-item-row ${itemTypeClassName}`;
    const inputType = 'checkbox';
    itemTypeClassName = checked ? `${itemTypeClassName}-checked` : itemTypeClassName;

    const requiredClassName =
        selectedValue === 'Select' ? 'fas fa-asterisk pc-red-asterick' : name === '' ? '' : 'fas fa-check-circle pc-accent-circle';

    return (
        <div className={itemTypeClassName} id={name} onClick={check} role={inputType}>
            {image && (
                <Image
                    className='ms-refine-configuration-item'
                    src={image || ''}
                    fallBackSrc={fallbackImage}
                    altText={name}
                    aria-checked={checked}
                    role={inputType}
                    gridSettings={gridSettings!}
                    loadFailureBehavior='empty'
                />
            )}
            <div className='pc-row-text-wrap'>
                <div className='pc-row-text'>{name}</div>
                <div className='pc-row-text-icon'>
                    <i className={requiredClassName} />
                </div>
            </div>
            {selectedValue && <div className='pc-row-text-wrap-selection'>{selectedValue}</div>}
        </div>
    );
};

export default ConfiguratorCheckBox;
