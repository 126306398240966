import * as React from 'react';

export interface IIngredientLineModifierProps {
    wholeIngredNames: string[];
    leftIngredNames: string[];
    rightIngredNames: string[];
    heldIngredNames: string[];
}

const IngredientLineModifier: React.FC<IIngredientLineModifierProps> = (props: IIngredientLineModifierProps) => {
    const { wholeIngredNames, leftIngredNames, rightIngredNames, heldIngredNames } = props;

    const _renderNames = () => <span className='ms-buybox__selection-text-init-cap'>{wholeIngredNames.join(', ')}</span>;
    const _renderLeftNames = () => <span className='ms-buybox__selection-text-init-cap'>{leftIngredNames.join(', ')}</span>;
    const _renderRightNames = () => <span className='ms-buybox__selection-text-init-cap'>{rightIngredNames.join(', ')}</span>;
    const _renderHeldNames = () => <span className='ms-buybox__selection-text-init-cap'>{heldIngredNames.join(', ')}</span>;

    const _renderLabel = () => {
        if (wholeIngredNames.length || leftIngredNames.length || rightIngredNames.length || heldIngredNames.length) {
            return <h5 className='modification_heading'>Your selection:</h5>;
        }
        return;
    };

    return (
        <>
            {_renderLabel()}
            {wholeIngredNames.length > 0 && <p className='lrwFix'>{_renderNames()}</p>}
            {leftIngredNames.length > 0 && <p className='lrwFix'>{_renderLeftNames()}</p>}
            {rightIngredNames.length > 0 && <p className='lrwFix'>{_renderRightNames()}</p>}
            {heldIngredNames.length > 0 && <p className='lrwFix'>{_renderHeldNames()}</p>}
        </>
    );
};

export default IngredientLineModifier;
