import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IState, LeftRightWhole } from '../../interfaces';
import { pizzaSectionClick } from '../action-creators';
import PizzaSections, { IPizzaSectionsDispatchProps, IPizzaSectionsProps } from '../components/pizza-sections';

const mapStateToProps = (state: IState): IPizzaSectionsProps => {
    return {
        allowLeftRight: !!state.customizeIngredients.allowLeftRight
    };
};

const mapDispatchToProps = (dispatch: Dispatch): IPizzaSectionsDispatchProps => ({
    click: (selected: LeftRightWhole) => dispatch(pizzaSectionClick(selected))
});

export default connect(mapStateToProps, mapDispatchToProps)(PizzaSections);
