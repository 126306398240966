import { connect } from 'react-redux';
import { IState } from '../../interfaces';
import { itemCheck, subitemCheck } from '../action-creators';
import ConfiguratorList, { IConfiguratorListDispatchProps, IConfiguratorListProps } from '../components/configurator-list';

const mapStateToProps = (state: IState): IConfiguratorListProps => {
    return { currentValues: state.customizeConfigurator?.ProductConfiguratorCurrentValues };
};

const mapDispatchToProps: IConfiguratorListDispatchProps = {
    itemCheck,
    subitemCheck
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfiguratorList);
