import * as React from 'react';
import { IIngredientSubstitute } from '../../interfaces';

export interface IIngredientSubstitueItemProps {
    item: IIngredientSubstitute;
    check?(payload: IIngredientSubstitute): void;
}

const IngredientSubstitueItem: React.FC<IIngredientSubstitueItemProps> = (props): JSX.Element => {
    const { item } = props;

    const _handleClick = () => {
        props.check && props.check(item);
    };

    return (
        <div role='none' className='row-md-4 substitute-item' onClick={_handleClick}>
            {item.Name}
        </div>
    );
};

export default IngredientSubstitueItem;
