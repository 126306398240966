import * as React from 'react';
import { LeftRightWhole } from '../../interfaces';
import { IModifierPizzaSectionPayload } from '../action-creators';

export interface IModifierPizzaSectionsProps {
    modifierGroupId: number;
    allowLeftRight?: boolean;
}

export interface IModifierPizzaSectionsDispatchProps {
    pizzaSectionClick?(payload: IModifierPizzaSectionPayload): void;
}

type Props = IModifierPizzaSectionsProps & IModifierPizzaSectionsDispatchProps;
const PizzaSections = (props: Props): JSX.Element => {
    const [selected, setSelected] = React.useState(LeftRightWhole.whole);
    const { modifierGroupId } = props;

    const handleLeftClick = () => {
        setSelected(LeftRightWhole.left);
        const payLoad = getPayload(LeftRightWhole.left, modifierGroupId);
        props.pizzaSectionClick && props.pizzaSectionClick(payLoad);
    };
    const handleWholeClick = () => {
        setSelected(LeftRightWhole.whole);
        const payLoad = getPayload(LeftRightWhole.whole, modifierGroupId);
        props.pizzaSectionClick && props.pizzaSectionClick(payLoad);
    };
    const handleRightClick = () => {
        setSelected(LeftRightWhole.right);
        const payLoad = getPayload(LeftRightWhole.right, modifierGroupId);
        props.pizzaSectionClick && props.pizzaSectionClick(payLoad);
    };

    const _renderLeftButton = (): JSX.Element => {
        return (
            <div
                className={`${selected === LeftRightWhole.left ? 'modifiersSideLeft modifiers-border' : 'modifiersSideLeft'}`}
                role='none'
                onClick={handleLeftClick}
            >
                <div
                    className={`${
                        selected === LeftRightWhole.left
                            ? 'modifiersSideBackground modifiers-side-selected'
                            : 'modifiersSideBackground modifiers-side'
                    }`}
                />
                <div className='modifiersSideBackgroundWhite' />
            </div>
        );
    };

    const _renderWholeButton = (): JSX.Element => {
        return (
            <div
                className={`${selected === LeftRightWhole.whole ? 'modifiersSide modifiers-border' : 'modifiersSide'}`}
                role='none'
                onClick={handleWholeClick}
            >
                <div
                    className={`${
                        selected === LeftRightWhole.whole
                            ? 'modifiersSideMiddle modifiers-side-selected'
                            : 'modifiersSideMiddle modifiers-side'
                    }`}
                />
            </div>
        );
    };

    const _renderRightButton = (): JSX.Element => {
        return (
            <div
                className={`${selected === LeftRightWhole.right ? 'modifiersSideRight modifiers-border' : 'modifiersSideRight'}`}
                role='none'
                onClick={handleRightClick}
            >
                <div className='modifiersSideBackgroundWhite' />
                <div
                    className={`${
                        selected === LeftRightWhole.right
                            ? 'modifiersSideBackground modifiers-side-selected'
                            : 'modifiersSideBackground modifiers-side'
                    }`}
                />
            </div>
        );
    };

    if (props.allowLeftRight) {
        return (
            <div className='customizeButtonModifiers'>
                {_renderLeftButton()}
                {_renderWholeButton()}
                {_renderRightButton()}
            </div>
        );
    } else {
        return <></>;
    }
};

function getPayload(lrh: LeftRightWhole, modifierGroupId: number): IModifierPizzaSectionPayload {
    return {
        section: lrh,
        modifierGroupId
    };
}

export default PizzaSections;
