import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createEpicMiddleware } from 'redux-observable';

import { initialDispatch } from '../customize-ingredients/action-creators';
import { rootEpic } from '../epics';
import reducer from '../reducer';

const epicMiddleware = createEpicMiddleware();
export const store = createStore(reducer, composeWithDevTools(applyMiddleware(epicMiddleware)));
epicMiddleware.run(rootEpic);

store.dispatch(initialDispatch());
