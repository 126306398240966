import { connect } from 'react-redux';
import {
    IIngredientSubstitute,
    IIngredientWithSections,
    IModifierLine,
    IModifierLocal,
    IState,
    LeftRightWhole,
    ModifierLineAction,
    ModifierLineType
} from '../../interfaces';
import { loadRecipe } from '../action-creators';
import CustomizeRecipeBox from '../components/customize-recipe-box';

const mapStateToProps = (state: IState) => {
    return {
        recipe: state.customizeRecipes.recipes,
        modifierLine: getModifierLines(state)
    };
};

const mapDispatchToProps = {
    loadRecipe
};

// prettier-ignore
const getModifierLines = (state: IState) => {
  const modifierLine: IModifierLine[] = [];
  const leftRightHeldIngredients: IModifierLine[] = state.customizeIngredients.leftRightModifiersArray.map(
    (item: IIngredientWithSections) => {
      return {
      Type: ModifierLineType.Ingredient,
      ItemId: item.ItemId,
      LineNum: item.LineNum,
      LeftRight: item.LeftRight || 0,
      Units: 0,
      Name: item.Name,
      ParentProductId: item.ProductId,
      ProductId: item.ProductRecId,
      UOM: item.UOM,
      Qty: item.QuantityUnit,
      Price: item.Price,
      Action: ModifierLineAction.None
    };}
  );
  const removedIngredient: IModifierLine[] = state.customizeIngredients.wholeModifiersArray.map(
    (item: IIngredientWithSections) => {
      return {
      Type: ModifierLineType.Ingredient,
      ItemId: item.ItemId,
      LineNum: item.LineNum,
      LeftRight: item.LeftRight || 0,
      Units: 0,
      Name: item.Name,
      ParentProductId: item.ProductId,
      ProductId: item.ProductRecId,
      UOM: item.UOM,
      Qty: item.QuantityUnit,
      Price: item.Price,
      Action: ModifierLineAction.None
    };}
  );
  const modifier: IModifierLine[] = state.customizeModifiers.modifierGroups
    .reduce((accumulated: IModifierLocal[], current) => {
      return [
        ...accumulated,
        ...current.currentModifiers.filter((item: IModifierLocal) => item.quantity > 0 )
      ];
    }, [])
    .map((item: IModifierLocal) => {
      return {
        Type: ModifierLineType.Modifier,
        ItemId: item.ItemId,
        LineNum: item.LineNum,
        LeftRight:
          item.section === LeftRightWhole.left? 1:
          item.section === LeftRightWhole.right ? 2: 0,
        Units: item.quantity,
        Name: item.Name,
        ParentProductId: item.ProductId,
        ProductId: item.ProductRecId,
        UOM: item.UOM,
        Qty: item.QuantityUnit,
        Price: item.Price,
        Action: ModifierLineAction.None
      };
    });
  let productId: number = 0;
  const substitution: IModifierLine[] = state.customizeIngredients.ingredientsForDifferentSections
    .filter((item: IIngredientWithSections) => item.substitutionLineNum !== 0)
    .reduce(( accumulated: IIngredientSubstitute[], current: IIngredientWithSections ) => {
        productId = current.ProductId;
        return [
          ...accumulated,
          ...((state.customizeIngredients.ingredientSubGroups &&
            state.customizeIngredients.ingredientSubGroups[
              current.SubGroupId
            ].filter((item: IIngredientSubstitute) => item.LineNum === current.substitutionLineNum)) || [])
        ];
      },
      []
    )
    .map((item: IIngredientSubstitute) => {
      return {
        Type: ModifierLineType.Ingredient,
        ItemId: item.ItemId,
        LineNum: item.LineNum,
        LeftRight: 0,
        Units: item.QuantityUnit,
        Name: item.Name,
        ParentProductId: productId === 0 ? undefined : productId,
        ProductId: item.ProductRecId,
        UOM: item.UOM,
        Qty: item.QuantityUnit,
        Price: item.Price,
        Action: ModifierLineAction.None
      };
    });
  modifierLine.push(
    ...leftRightHeldIngredients,
    ...removedIngredient,
    ...modifier,
    ...substitution
  );
  return modifierLine;
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomizeRecipeBox);
