import { buildCacheKey } from '@msdyn365-commerce-modules/retail-actions';
import {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    ICommerceApiSettings
} from '@msdyn365-commerce/core';
import { getDefaultComponentsAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { ProductComponent } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';

/**
 * Input class for get default components data action
 */
export class GetDefaultComponentsExtInput implements IActionInput {
    public recordId: number;
    public channelId: number;
    private apiSettings: ICommerceApiSettings;

    constructor(recordId: number, channelId: number, apiSettings: ICommerceApiSettings) {
        this.recordId = recordId;
        this.channelId = channelId;
        this.apiSettings = apiSettings;
    }

    public getCacheKey = () => buildCacheKey(`ProductComponent`, this.apiSettings);
    public getCacheObjectType = () => 'ProductComponent';
    public dataCacheType = (): CacheType => 'request';
}

export async function getDefaultComponentsAction(input: GetDefaultComponentsExtInput, ctx: IActionContext): Promise<ProductComponent[]> {
    return getDefaultComponentsAsync({ callerContext: ctx, queryResultSettings: {} }, input.recordId, input.channelId);
}

export default createObservableDataAction({
    action: <IAction<ProductComponent[]>>getDefaultComponentsAction
});
