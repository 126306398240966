import { connect } from 'react-redux';
import { IState } from '../../interfaces';
import { slotCheck } from '../action-creators';
import ComponentsList, { IComponentsListDispatchProps, IComponentsListProps } from '../components/slot-list';

const getSelectedComponents = (state: IState) => {
    return state.customizeComponents.components;
};

const mapStateToProps = (state: IState): IComponentsListProps => {
    return { slotComponents: getSelectedComponents(state) };
};

const mapDispatchToProps: IComponentsListDispatchProps = {
    slotCheck
};

export default connect(mapStateToProps, mapDispatchToProps)(ComponentsList);
