import { connect } from 'react-redux';
import { IState } from '../../interfaces';
import { loadConfigurator } from '../action-creators';
import CustomizeConfiguratorBox from '../components/customize-configurator-box';

const mapStateToProps = (state: IState) => {
    return {
        components: state.customizeConfigurator?.Components,
        currentValues: state.customizeConfigurator?.ProductConfiguratorCurrentValues
    };
};

const mapDispatchToProps = {
    loadConfigurator
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomizeConfiguratorBox);
