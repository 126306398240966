// tslint:disable

/**
 * AddCommerceListLinePropertyResponse entity interface.
 */
export interface IAddCommerceListLinePropertyResponse {
    RetailWishlistLineRecId?: number;
    Property?: string;
}

/**
 * GetCommerceListLinePropertyResponse entity interface.
 */
export interface IGetCommerceListLinePropertyResponse {
    RetailWishlistLineRecId?: number;
    Property?: string;
}

/**
 * AddCommerceListLinePropertyResponse entity class.
 */
export class AddCommerceListLinePropertyResponseExtensionClass implements IAddCommerceListLinePropertyResponse {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public RetailWishlistLineRecId: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Property: string;

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.RetailWishlistLineRecId = odataObject.RetailWishlistLineRecId ? parseInt(odataObject.RetailWishlistLineRecId, 10) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Property = odataObject.Property;
    }
}

/**
 * GetCommerceListLinePropertyResponse entity class.
 */
export class GetCommerceListLinePropertyResponseExtensionClass implements IGetCommerceListLinePropertyResponse {
    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public RetailWishlistLineRecId: number;

    // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
    public Property: string;

    // Navigation properties names

    /**
     * Construct an object from odata response.
     * @param {any} odataObject The odata result object.
     */
    constructor(odataObject?: any) {
        odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.RetailWishlistLineRecId = odataObject.RetailWishlistLineRecId ? parseInt(odataObject.RetailWishlistLineRecId, 10) : 0;

        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.Property = odataObject.Property;
    }
}
