import { connect } from 'react-redux';
import { IState } from '../../interfaces';
import { itemCheck } from '../action-creators';
import RecipesList, { IRecipesListDispatchProps, IRecipesListProps } from '../components/recipe-list';

const getRecipes = (state: IState) => {
    return state.customizeRecipes.recipes;
};

const mapStateToProps = (state: IState): IRecipesListProps => {
    return { recipes: getRecipes(state) };
};

const mapDispatchToProps: IRecipesListDispatchProps = {
    itemCheck
};

export default connect(mapStateToProps, mapDispatchToProps)(RecipesList);
