import classnames from 'classnames';
import { getFallbackImageUrl } from '@msdyn365-commerce-modules/retail-actions';
import { IActionContext } from '@msdyn365-commerce/core';
import { IProductConfiguratorCartLineCurrentValue } from '../../../lifestyle-modify-product';
import { IAttributeWithData } from '../../interfaces';
import { IItemCheckPayload } from '../action-creators';
import ConfiguratorCheckBox from './configurator-checkbox';
import * as React from 'react';
import _ from 'lodash';

export interface IConfiguratorItemProps {
    item: IAttributeWithData;
    currentValue: IProductConfiguratorCartLineCurrentValue | undefined;
    actionContext: IActionContext;
}

export interface IConfiguratorItemDispatchProps {
    check?(payload: IItemCheckPayload): void;
}

type Props = IConfiguratorItemProps & IConfiguratorItemDispatchProps;
const ConfiguratorItem = (props: Props): JSX.Element => {
    const { item, currentValue, actionContext, check } = props;

    const _handleConfiguratorCheckBoxChange = () => {
        if (!item.checked) {
            item.checked;
            check && check({ component: item });
        }
    };

    const attributeClass = classnames('pc-item-line', {
        'pc-item-line-selected': item.checked
    });

    // restore user selected values from modify cart
    if (currentValue && currentValue.value) {
        React.useEffect(() => {
            // eslint-disable-next-line
            const currentItemValue = _.find(item.Domain?.Values, item => {
                // eslint-disable-next-line
                return item.Value === currentValue.value;
            });

            item.DisplayValue = currentItemValue?.DisplayValue;
        }, [currentValue]);
    }

    const attributeDisplay = classnames({ hidden: item.IsConditionalHidden || item.IsUnconditionalHidden });
    return (
        <div className={`pc-item ${attributeDisplay}`}>
            <div role='none' className={`item-line ${attributeClass}`}>
                <ConfiguratorCheckBox
                    name={item.DisplayName ? item.DisplayName : ''}
                    selectedValue={
                        item.DisplayValue ? item.DisplayValue : item.IsUnconditionalMandatory || item.IsConditionalMandatory ? 'Select' : ''
                    }
                    checked={item.checked}
                    check={_handleConfiguratorCheckBoxChange}
                    image={item.ImageUrl}
                    fallbackImage={getFallbackImageUrl(item.UniqueId, actionContext.requestContext.apiSettings)}
                    gridSettings={actionContext.requestContext.gridSettings}
                />
            </div>
        </div>
    );
};

export default ConfiguratorItem;
