import classnames from 'classnames';
import * as React from 'react';
import { IRecipeWithData } from '../../interfaces';
import { IItemCheckPayload } from '../action-creators';
import RecipeCheckBox from './recipe-checkbox';
export interface IRecipeItemProps {
    item: IRecipeWithData;
}

export interface IRecipeItemDispatchProps {
    check?(payload: IItemCheckPayload): void;
}

type Props = IRecipeItemProps & IRecipeItemDispatchProps;
const RecipeItem = (props: Props): JSX.Element => {
    const { item, check } = props;

    const _handleRecipeCheckBoxChange = () => {
        check && check({ recipe: item, checked: !item.checked });
    };

    const ingredientClass = classnames('ingredient-item-line', {
        'ingredient-item-line-selected': item.checked
    });

    return (
        <div className='ingredient-item col-md-4 col-sm-6 col-12'>
            <div role='none' className={`item-line ${ingredientClass}`}>
                <RecipeCheckBox name={item.name} checked={item.checked} check={_handleRecipeCheckBoxChange} />
            </div>
        </div>
    );
};

export default RecipeItem;
