import * as React from 'react';

export interface ICustomizeModifierImageProps {
    name: string;
    image?: string;
}

const handleOnErrorImg = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const target = e.target as HTMLImageElement;
    target.style.visibility = 'hidden';
};

const CustomizeModifierImage: React.FunctionComponent<ICustomizeModifierImageProps> = (props: ICustomizeModifierImageProps) => {
    const { name, image } = props;
    return (
        <div className='item-row' id={name}>
            {image && <img className='ms-refine-submenu-item as-modifiers-image' src={image} alt={name} onError={handleOnErrorImg} />}
            <span className='item-name ms-refine-submenu-item__label'>{name}</span>
        </div>
    );
};

export default CustomizeModifierImage;
