import * as React from 'react';
import { IAttributeWithData } from '../../interfaces';
import { IItemCheckPayload } from '../action-creators';

export interface IConfiguratorBoxNextProps {
    items?: IAttributeWithData[];
    value: string;
    enable: boolean;
    check?(payload: IItemCheckPayload): void;
}

const ConfiguratorBoxNext = (props: IConfiguratorBoxNextProps): JSX.Element => {
    const { items, value, enable, check } = props;

    const _handleConfiguratorNextChange = () => {
        if (items && items.length > 0) {
            for (let x = 0; x < items.length; x++) {
                if (!items[x].DisplayValue && (items[x].IsUnconditionalMandatory || items[x].IsConditionalMandatory)) {
                    check && check({ component: items[x] });
                    break;
                }
            }
        }
    };

    return (
        <button className='pc-next-button' onClick={_handleConfiguratorNextChange} disabled={!enable}>
            {value}
        </button>
    );
};

export default ConfiguratorBoxNext;
