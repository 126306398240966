import { connect } from 'react-redux';
import { IState } from '../../interfaces';
import { loadSlotComponents } from '../action-creators';
import SlotComponentsBox from '../components/customize-slotComponent-box';

const mapStateToProps = (state: IState) => {
    return {
        component: state.customizeComponents?.components
    };
};

const mapDispatchToProps = {
    loadSlotComponents
};

export default connect(mapStateToProps, mapDispatchToProps)(SlotComponentsBox);
