import { IProductConfiguratorCartLineCurrentValue } from '../../../lifestyle-modify-product';
import { IComponentWithData } from '../../interfaces';
import { ILoadConfiguratorPayload } from '../action-creators';
import ConfiguratorList from '../containers/configurator-list';
import * as React from 'react';
import _ from 'lodash';
import { IActionContext } from '@msdyn365-commerce/core';

interface IOwnProps {
    actionContext: IActionContext;
    title: string;
    component: IComponentWithData;
    configuratorCurrentValues: IProductConfiguratorCartLineCurrentValue[] | undefined;
    onchange?(payload: IComponentWithData | null, selectedAttribute: number, selectedDomain: number, forceUpdate?: boolean): void;
}

interface ICustomizeRecipeBoxDispatchProps {
    loadConfigurator?(payload: ILoadConfiguratorPayload): void;
}

interface IProps extends ICustomizeRecipeBoxDispatchProps, IOwnProps {}

const CustomizeConfiguratorBox: React.FC<IProps> = (props: IProps): JSX.Element => {
    let defaultConfigurator = [];
    let selectedAttribute: number = 999;
    let selectedDomain: number = 999;

    if (props.component) {
        // TODO rewrite this with lodash
        defaultConfigurator = props.component?.Components ? props.component.Components : [];
        defaultConfigurator.forEach(comp => {
            if (comp.Attributes) {
                for (let i: number = 0; i < comp.Attributes?.length; i++) {
                    if (comp.Attributes[i].checked) {
                        selectedAttribute = i;
                        const domains = comp.Attributes[i].Domain?.Values;
                        if (comp.Attributes[i].Domain && domains) {
                            for (let j: number = 0; j < domains.length; j++) {
                                if (domains[j].checked) {
                                    selectedDomain = j;
                                }
                            }
                        }
                    }
                }
            }
        });
    }

    props.onchange && props.onchange(props.component, selectedAttribute, selectedDomain);

    React.useEffect(() => {
        props.loadConfigurator &&
            props.loadConfigurator({
                components: props.component,
                currentValues: props.configuratorCurrentValues,
                title: props.title
            });
    }, [props.component, props.configuratorCurrentValues]);

    return (
        <div className='customize-ingredients-box' style={{ position: 'relative' }}>
            <ConfiguratorList {...props} />
        </div>
    );
};

export default CustomizeConfiguratorBox;
