import { ActionsObservable, ofType } from 'redux-observable';
import { concat, empty, of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { ActionTypes } from '../../actions-types';
import { IngredientEditability } from '../../interfaces';
import { closeSubstitueBox, getSubstitute, IItemClick, ISubstituteCheck, makeSubstitution } from '../action-creators';

export const onIngredientSelect = (action$: ActionsObservable<IItemClick>) =>
    action$.pipe(
        ofType(ActionTypes.ITEM_CLICK),
        filter(actionFilter$ => actionFilter$.payload.checked),
        mergeMap(actionMerge$ => {
            const isSubstitue = actionMerge$.payload.Edit === IngredientEditability.Substitute;
            if (isSubstitue) {
                return of(getSubstitute(actionMerge$.payload));
            }
            return empty();
        })
    );

export const onSubstituteSelect = (action$: ActionsObservable<ISubstituteCheck>) =>
    action$.pipe(
        ofType(ActionTypes.SUBSTITUTE_CHECK),
        mergeMap(actionMerge$ => {
            return concat(of(closeSubstitueBox()), of(makeSubstitution(actionMerge$.payload)));
        })
    );
