import { ActionTypes } from '../../actions-types';
import { ICustomizeModifiers } from '../../interfaces';
import { CustomizeModifiersActions, IModifierEditabilityPayload } from '../action-creators';
import { populateInitialModifiersCollection } from './customize-modifiers-reducer-helper';

const initialState: ICustomizeModifiers = {
    modifierGroups: [],
    currentGroupId: 0
};

export default (state = initialState, action: CustomizeModifiersActions): ICustomizeModifiers => {
    switch (action.type) {
        case ActionTypes.LOAD_MODIFIERS:
            return {
                ...state,
                modifierGroups: populateInitialModifiersCollection(action.payload.modifiers, action.payload.modifierLine),
                currentGroupId: action.payload.modifiers && action.payload.modifiers.length > 0 ? action.payload.modifiers[0].GroupId : 0,
                modifierLine: action.payload.modifierLine
            };
        case ActionTypes.MODIFIER_PIZZA_SECTION_CLICK:
            const modiferGroupId = action.payload.modifierGroupId;
            const tempModifierGroups = state.modifierGroups.map(item => {
                if (item.GroupId === modiferGroupId) {
                    item.leftRightWhole = action.payload.section;
                }
                return item;
            });
            return {
                ...state,
                modifierGroups: tempModifierGroups,
                currentGroupId: action.payload.modifierGroupId
            };
        case ActionTypes.MODIFIER_INCREASE_CLICK:
        case ActionTypes.MODIFIER_DECREASE_CLICK:
            return increaseDecreaseClick(state, action.payload);
        default:
            return state;
    }
};

const increaseDecreaseClick = (state: ICustomizeModifiers, payload: IModifierEditabilityPayload) => {
    return {
        ...state,
        modifierGroups: state.modifierGroups.map(modifierGroup => {
            if (modifierGroup.GroupId === payload.modifier.modifierGroupId) {
                modifierGroup.currentModifiers.map(modifierItem => {
                    if (modifierItem.LineNum === payload.modifier.LineNum && modifierItem.section === payload.modifier.section) {
                        modifierItem.quantity = payload.quantity;
                    }
                    return modifierItem;
                });
            }
            return modifierGroup;
        })
    };
};
