import * as React from 'react';
import { IModifierLine, IRecipeWithData } from '../../interfaces';
import { ILoadRecipePayload } from '../action-creators';
import RecipesList from '../containers/recipe-list';
import IngredientsBoxHeader from './recipe-box-header';

interface IOwnProps {
    title: string;
    recipes: IRecipeWithData[];
    modifierLine: IModifierLine[];
    onchange?(payload: IRecipeWithData[] | null, selectedIndex: number, modifierLines: IModifierLine[] | null): void;
}

interface ICustomizeRecipeBoxDispatchProps {
    loadRecipe?(payload: ILoadRecipePayload): void;
}

interface IProps extends ICustomizeRecipeBoxDispatchProps, IOwnProps {}

const CustomizeRecipeBox: React.FC<IProps> = (props: IProps): JSX.Element => {
    let defaultRecipes = [];
    let selectedIndex: number = 0;
    let i: number = 0;
    if (props.recipes) {
        defaultRecipes = props.recipes;
        for (i = 0; i < defaultRecipes.length; i++) {
            if (defaultRecipes[i].checked) {
                selectedIndex = i;
            }
        }
    }

    props.onchange && props.onchange(props.recipes, selectedIndex, props.modifierLine);

    React.useEffect(() => {
        props.loadRecipe &&
            props.loadRecipe({
                recipes: props.recipes,
                title: props.title
            });
    }, [props.recipes]);

    return (
        <div className='customize-ingredients-box' style={{ position: 'relative' }}>
            <IngredientsBoxHeader>
                <div>
                    <h5 className='ms-buybox__customize-ingredients-heading'>Customize: {props.title}</h5>
                </div>
            </IngredientsBoxHeader>
            <RecipesList />
        </div>
    );
};

export default CustomizeRecipeBox;
