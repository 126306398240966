import { connect } from 'react-redux';
import { IState } from '../../interfaces';
import { closeSubstitueBox, substituteCheck } from '../action-creators';
import IngredientSubstitueBox, { IIngredientSubstitueBoxDispatchProps } from '../components/ingredient-substitute-box';

const mapStateToProps = (state: IState) => {
    return {
        substitutes: state.customizeIngredients.substitutes || [],
        name: state.customizeIngredients.substitutionIngredient?.Name || ''
    };
};

const mapDispatchToProps: IIngredientSubstitueBoxDispatchProps = {
    closeSubstitueBox,
    substituteCheck
};

export default connect(mapStateToProps, mapDispatchToProps)(IngredientSubstitueBox);
