import { IActionContext } from '@msdyn365-commerce/core';
import { IProductConfiguratorCartLineCurrentValue } from '../../../lifestyle-modify-product';
import { IAttributeWithData, IComponentWithData, IDomainWithValue } from '../../interfaces';
import { IItemCheckPayload, ISubItemCheckPayload } from '../action-creators';
import ConfiguratorBoxHeader from './configurator-box-header';
import ConfiguratorBoxNext from './configurator-box-next';
import ConfiguratorItem from './configurator-item';
import ConfiguratorSubItem from './configurator-sub-item ';
import * as React from 'react';
import _ from 'lodash';

export interface IConfiguratorListProps {
    title?: string;
    actionContext?: IActionContext;
    component?: IComponentWithData;
    currentValues?: IProductConfiguratorCartLineCurrentValue[] | undefined;
    onchange?(payload: IComponentWithData | null, selectedAttribute: number, selectedDomain: number, forceUpdate?: boolean): void;
}

export interface IConfiguratorListDispatchProps {
    itemCheck?(payload: IItemCheckPayload): void;
    subitemCheck?(payload: ISubItemCheckPayload): void;
}

type Props = IConfiguratorListProps & IConfiguratorListDispatchProps;

const ConfiguratorList: React.FC<Props> = (props: Props) => {
    const { component, currentValues, actionContext, itemCheck, subitemCheck, onchange } = props;

    const _renderAttributes = (attributes: IAttributeWithData[] | undefined) =>
        attributes &&
        attributes?.map((attribute: IAttributeWithData, attributeIndex) => {
            // restore selection from modify cart (item UI)
            // eslint-disable-next-line
            const currentItemValue = _.find(currentValues, currentValue => {
                // eslint-disable-next-line
                return currentValue.uniqueId === attribute.UniqueId;
            });

            return (
                <ConfiguratorItem
                    key={attribute.InstanceId?.toString()}
                    item={attribute}
                    currentValue={currentItemValue}
                    check={itemCheck}
                    actionContext={actionContext!}
                />
            );
        });

    const _renderAttributeContainerHeaderBorder = (attributes: IAttributeWithData[] | undefined): string => {
        let header = '';
        const selectedAttribute = attributes && attributes?.filter((attrData: IAttributeWithData) => attrData.checked)[0];
        if (selectedAttribute) {
            const displayValue = selectedAttribute.DisplayValue ? selectedAttribute.DisplayValue : 'REQUIRED';
            header = displayValue;
        }
        return header;
    };

    const _renderAttributeValueContainerHeaderText = (attributes: IAttributeWithData[] | undefined): string => {
        let header = '';
        const selectedAttribute = attributes && attributes?.filter((attrData: IAttributeWithData) => attrData.checked)[0];
        if (selectedAttribute) {
            const displayValue = selectedAttribute.DisplayValue ? selectedAttribute.DisplayValue : 'REQUIRED';
            header = selectedAttribute.DisplayName ? `Select ${selectedAttribute.DisplayName} : ${displayValue}` : '';
        }
        return header;
    };

    const _nextButtonEnabled = (items: IAttributeWithData[] | undefined): boolean => {
        if (items && items.length > 0) {
            for (let x = 0; x < items.length; x++) {
                if (items[x].checked) {
                    if (items[x].DisplayValue === '') {
                        return false;
                    }
                } else if (!items[x].DisplayValue && (items[x].IsUnconditionalMandatory || items[x].IsConditionalMandatory)) {
                    return true;
                }
            }
        }
        return false;
    };

    const _renderAttributeValue = (attributes: IAttributeWithData[] | undefined) =>
        attributes &&
        attributes[0] &&
        attributes[0].Domain?.Values?.map((domain: IDomainWithValue) => (
            <ConfiguratorSubItem key={domain.Value?.toString()} item={domain} check={subitemCheck} />
        ));

    // set default attribute values
    // call onchange on attribute that has default values but is also not rendered at first (only 1st attribute gets rendered at the beginning and thus the rest of the attribute values do not get selected)
    // !currentItemValue is to make sure that it is not restoring from cart, so if there is a default value then it can be selected
    React.useEffect(() => {
        setTimeout(() => {
            if (
                component &&
                component?.Components &&
                component?.Components[0] &&
                component?.Components[0].Attributes &&
                component?.Components[0].Attributes[0]
            ) {
                // eslint-disable-next-line
                let attribute = component?.Components[0].Attributes[0];

                // eslint-disable-next-line
                const currentItemValue = _.find(currentValues, currentValue => {
                    // eslint-disable-next-line
                    return currentValue.uniqueId == attribute.UniqueId;
                });

                if (!currentItemValue && attribute.DisplayValue && component) {
                    if (_.find(attribute.Domain?.Values, { Value: attribute.Default })) {
                        // eslint-disable-next-line
                        let defaultDomainIndex = _.findIndex(attribute.Domain?.Values, { Value: attribute.Default });
                        // call onchange to update product configurator if default domain value exists
                        if (defaultDomainIndex > -1) {
                            onchange && onchange(component, 0, defaultDomainIndex, true);
                        }
                    }
                }
            }
        }, 2000);
    }, []);

    // restore selection from modify cart (item UI)
    let attributesWithRestoredCurrentValues: IAttributeWithData[] = [];

    if (component?.Components && component?.Components[0] && component?.Components[0].Attributes?.filter(x => x.checked)) {
        attributesWithRestoredCurrentValues = component?.Components[0].Attributes;

        React.useEffect(() => {
            if (attributesWithRestoredCurrentValues && attributesWithRestoredCurrentValues[0]) {
                attributesWithRestoredCurrentValues[0].Domain?.Values?.map((domain: IDomainWithValue) => {
                    // eslint-disable-next-line
                    const currentItemValue = _.find(currentValues, currentValue => {
                        // eslint-disable-next-line
                        return currentValue.uniqueId == attributesWithRestoredCurrentValues[0].UniqueId;
                    });

                    // restore selection from modify cart (sub-item UI)
                    // eslint-disable-next-line
                    if (domain.Value == currentItemValue?.value && !domain.checked) {
                        domain.checked = true;
                        subitemCheck && subitemCheck({ component: domain, checked: domain.checked });
                    }
                });
            }
        }, [currentValues]);
    }

    return (
        <div>
            {/* renders the attributes container header text */}
            <ConfiguratorBoxHeader>
                <h4 className='ms-buybox__customize-ingredients-heading-pc heading-pc-border'>
                    {props.title ? `Customize : ${props.title} ` : ''}
                </h4>
            </ConfiguratorBoxHeader>
            {/* renders the attributes */}
            <div className='ingredients-item-container pc-attributes'>
                {component?.Components && component?.Components[0] && _renderAttributes(component?.Components[0].Attributes)}
            </div>
            {/* renders the attributes values components */}
            <div className='customize-ingredients-box' style={{ position: 'relative' }}>
                {/* renders the attributes values components container header*/}
                <ConfiguratorBoxHeader>
                    <h4
                        className={`ms-buybox__customize-ingredients-heading-pc 
            ${
                /* renders the attributes value container header border (required or not required red border) */
                _renderAttributeContainerHeaderBorder(component?.Components && component.Components[0].Attributes) === 'REQUIRED'
                    ? 'heading-pc-border-alert'
                    : 'heading-pc-border'
            }`}
                    >
                        {/* renders the SELECT text inside the attributes value container header */}
                        {_renderAttributeValueContainerHeaderText(component?.Components && component.Components[0].Attributes)}
                    </h4>
                    {/* renders the next selection button */}
                    <ConfiguratorBoxNext
                        items={component?.Components && component.Components[0].Attributes}
                        value={'NEXT'}
                        check={itemCheck}
                        enable={_nextButtonEnabled(component?.Components && component.Components[0].Attributes)}
                    />
                </ConfiguratorBoxHeader>
                {/* renders the values for the current attribute */}
                <div className='ingredients-item-container pc-attribute-values'>
                    {component?.Components &&
                        component?.Components[0] &&
                        _renderAttributeValue(attributesWithRestoredCurrentValues?.filter(x => x.checked))}
                </div>
            </div>
        </div>
    );
};

export default ConfiguratorList;
