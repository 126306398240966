import * as React from 'react';
import { IProductComponentWithData } from '../../interfaces';
import { ISlotCheckPayload } from '../action-creators';
import IngredientsBoxHeader from './slot-box-header';
import SlotComponentItem from './slot-item';

export interface IComponentsListProps {
    slotComponents?: IProductComponentWithData[];
    onSlotchange?(payload: IProductComponentWithData[] | null, selectedIndex: number): void;
}

export interface IComponentsListDispatchProps {
    slotCheck?(payload: ISlotCheckPayload): void;
}

type Props = IComponentsListProps & IComponentsListDispatchProps;
const ComponentsList: React.FC<Props> = (props: Props) => {
    const { slotComponents, slotCheck } = props;
    const _renderComponents = () => {
        let substituteComponent: IProductComponentWithData[] = [];
        if (slotComponents && slotComponents !== undefined && slotComponents.length > 0) {
            substituteComponent = slotComponents.filter(x => x.slot.ProductId === x.defaultProductId);
        }
        const renderComponent = () =>
            slotComponents &&
            slotComponents
                .filter(x => x.slot.ProductId !== x.defaultProductId)
                .map((component: IProductComponentWithData) => (
                    <SlotComponentItem key={component.slot.ProductId.toString()} item={component} slotCheck={slotCheck} />
                ));

        return (
            <div className='customize-ingredients-box' style={{ position: 'relative' }}>
                <IngredientsBoxHeader>
                    <div>
                        <h5 className='ms-buybox__customize-ingredients-heading'>
                            Substitute: {substituteComponent && substituteComponent[0].slot.Name}
                        </h5>
                    </div>
                </IngredientsBoxHeader>
                <div className='ingredients-item-container'>{props.slotComponents && renderComponent()}</div>
            </div>
        );
    };

    return <div>{slotComponents && slotComponents.length > 1 && _renderComponents()}</div>;
};

export default ComponentsList;
