import { IDomainValue } from '../../../actions/PCEntities';
import { IProductConfiguratorCartLineCurrentValue } from '../../../lifestyle-modify-product';
import { ActionTypes } from '../../actions-types';
import { IAttributeWithData, IComponentWithData } from '../../interfaces';

export interface ILoadConfiguratorPayload {
    title: React.ReactNode;
    components: IComponentWithData;
    currentValues: IProductConfiguratorCartLineCurrentValue[] | undefined;
    onComponentsChange?(payload: IComponentWithData | null, selectedAttribute: number, selectedDomain: number): void;
}

export interface ILoadConfigurator {
    type: ActionTypes.LOAD_CONFIGURATOR;
    payload: ILoadConfiguratorPayload;
}

export const loadConfigurator = (payload: ILoadConfiguratorPayload): ILoadConfigurator => ({
    type: ActionTypes.LOAD_CONFIGURATOR,
    payload
});

export interface IItemCheckPayload {
    component: IAttributeWithData;
}

export interface ISubItemCheckPayload {
    component: IDomainValue;
    checked: boolean;
}

export interface IItemCheck {
    type: ActionTypes.ATTRIBUTE_CHECK;
    payload: IItemCheckPayload;
}

export interface ISubItemCheck {
    type: ActionTypes.DOMAIN_CHECK;
    payload: ISubItemCheckPayload;
}

export const itemCheck = (payload: IItemCheckPayload): IItemCheck => ({
    type: ActionTypes.ATTRIBUTE_CHECK,
    payload
});

export const subitemCheck = (payload: ISubItemCheckPayload): ISubItemCheck => ({
    type: ActionTypes.DOMAIN_CHECK,
    payload
});

export type CustomizeRecipeActions = ILoadConfigurator | IItemCheck | ISubItemCheck;
