import * as React from 'react';
import { EditabilityText, IngredientEditability } from '../../interfaces';

export interface IIngredientEditabilityButtonProps {
    children?: JSX.Element | IngredientEditability | EditabilityText;
    enableIncrease?: boolean;
    enableDecrease?: boolean;
    showEditabilityButton: boolean;
    increase?(e: React.MouseEvent<HTMLButtonElement>): void;
    decrease?(e: React.MouseEvent<HTMLButtonElement>): void;
}

const _showIngredientEditabilityButton = (props: IIngredientEditabilityButtonProps): {} => {
    return props.showEditabilityButton ? { visibility: 'visible' } : { visibility: 'hidden' };
};

const IngredientEditabilityButton = (props: IIngredientEditabilityButtonProps): JSX.Element => {
    const { enableIncrease, enableDecrease, increase, decrease } = props;
    return (
        <a style={_showIngredientEditabilityButton(props)} className='item-row ms-refine-submenu-item-row center-align'>
            <button disabled={!enableDecrease} className='decrement quantity__controls ingredientcontrol' onClick={decrease}>
                <span className='fas fa-minus quantity__controls-glyph' />
            </button>
            <div className='item-label item-edit-label ingredients-edit-label'>
                <label className='quantity-input'>{props.children}</label>
            </div>
            <button disabled={!enableIncrease} className='increment quantity__controls ingredientcontrol' onClick={increase}>
                <span className='fas fa-plus quantity__controls-glyph' />
            </button>
        </a>
    );
};

export default IngredientEditabilityButton;
