import classnames from 'classnames';
import * as React from 'react';
import { EditabilityText, IIngredientWithSections, IngredientEditability } from '../../interfaces';
import { IEditabilityPayload, IItemCheckPayload } from '../action-creators';
import IngredientCheckBox from './ingredient-checkbox';
import IngredientEditabilityButton from './ingredient-editability-button';

export interface IIngredientItemProps {
    item: IIngredientWithSections;
}

export interface IIngredientItemDispatchProps {
    click?(payload: IIngredientWithSections): void;
    check?(payload: IItemCheckPayload): void;
    increase?(payload: IEditabilityPayload): void;
    decrease?(payload: IEditabilityPayload): void;
}

type Props = IIngredientItemProps & IIngredientItemDispatchProps;
const IngredientItem = (props: Props): JSX.Element => {
    const { item, check, increase, decrease, click } = props;

    const _handleClick = () => {
        click && click(item);
    };
    const _handleIngredientCheckBoxChange = () => {
        check && check({ ingredient: item, checked: !item.checked });
    };

    const _handleIncreaseButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (item.editabilityOption === EditabilityText.Normal) {
            increase && increase({ ingredient: item, text: EditabilityText.Extra });
        } else {
            increase && increase({ ingredient: item, text: EditabilityText.Normal });
        }
    };

    const _handleDecreaseButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (item.editabilityOption === EditabilityText.Normal) {
            decrease && decrease({ ingredient: item, text: EditabilityText.Light });
        } else {
            decrease && decrease({ ingredient: item, text: EditabilityText.Normal });
        }
    };

    const hasIncreaseOption = item.Edit === IngredientEditability.IncreaseOnly || item.Edit === IngredientEditability.IncreaseDecrease;
    const hasDecreaseOption = item.Edit === IngredientEditability.DecreaseOnly || item.Edit === IngredientEditability.IncreaseDecrease;

    const enableIncrease = hasIncreaseOption && item.checked;
    const enableDecrease = hasDecreaseOption && item.checked;

    const showEditabilityButton = enableIncrease && enableDecrease;

    const ingredientClass = classnames('ingredient-item-line', {
        'ingredient-item-line-selected': item.checked
    });

    return (
        <div className='ingredient-item col-md-4 col-sm-6 col-12'>
            <div role='none' onClick={_handleClick} className={`item-line ${ingredientClass}`}>
                <IngredientCheckBox name={item.Name} checked={item.checked} check={_handleIngredientCheckBoxChange} image={item.Image} />

                <IngredientEditabilityButton
                    enableIncrease={enableIncrease}
                    enableDecrease={enableDecrease}
                    increase={_handleIncreaseButtonClick}
                    decrease={_handleDecreaseButtonClick}
                    showEditabilityButton={showEditabilityButton}
                >
                    {item.editabilityOption}
                </IngredientEditabilityButton>
            </div>
        </div>
    );
};

export default IngredientItem;
