import { connect } from 'react-redux';
import { IState, LeftRightWhole } from '../../interfaces';
import IngredientLineModifier, { IIngredientLineModifierProps } from '../components/ingredient-line-modifier';
import { getSelectedLeftItemNames, getSelectedRightItemNames, getSelectedWholeItemNames } from '../selectors';

const mapStateToProps = (state: IState): IIngredientLineModifierProps => {
    const left: string = `${LeftRightWhole.left}: `;
    const right: string = `${LeftRightWhole.right}: `;

    let selectedWholeItemNames = getSelectedWholeItemNames(state);
    let selectedLeftItemNames = state.customizeIngredients.allowLeftRight ? getSelectedLeftItemNames(state) : [];
    let selectedRightItemNames = state.customizeIngredients.allowLeftRight ? getSelectedRightItemNames(state) : [];

    const unselectedItemNames = Array.from(
        new Set(state.customizeIngredients.ingredientsForDifferentSections.filter(item => !item.checked).map(item => `HOLD ${item.Name}`))
    );

    state.customizeModifiers.modifierGroups.forEach(key => {
        selectedWholeItemNames = selectedWholeItemNames.concat(
            key.currentModifiers
                .filter(item => item.quantity > 0 && item.section === LeftRightWhole.whole)
                .map(item => `ADD ${item.quantity} ${item.Name}`)
        );
    });

    state.customizeModifiers.modifierGroups.forEach(key => {
        selectedLeftItemNames = selectedLeftItemNames.concat(
            key.currentModifiers
                .filter(item => item.quantity > 0 && item.section === LeftRightWhole.left)
                .map(item => `ADD ${item.quantity} ${item.Name}`)
        );
    });

    state.customizeModifiers.modifierGroups.forEach(key => {
        selectedRightItemNames = selectedRightItemNames.concat(
            key.currentModifiers
                .filter(item => item.quantity > 0 && item.section === LeftRightWhole.right)
                .map(item => `ADD ${item.quantity} ${item.Name}`)
        );
    });

    selectedLeftItemNames = selectedLeftItemNames.map((item, i) => (i === 0 ? left + item : item));

    selectedRightItemNames = selectedRightItemNames.map((item, i) => (i === 0 ? right + item : item));

    return {
        wholeIngredNames: selectedWholeItemNames,
        leftIngredNames: selectedLeftItemNames,
        rightIngredNames: selectedRightItemNames,
        heldIngredNames: unselectedItemNames
    };
};

export default connect(mapStateToProps)(IngredientLineModifier);
