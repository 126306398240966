import * as React from 'react';

export interface IModifiersBoxHeaderProps {
    children: React.ReactElement | React.ReactElement[];
}

const ModifiersBoxHeader = (props: IModifiersBoxHeaderProps): JSX.Element => {
    return <div className='as-modifiers-container'>{props.children}</div>;
};

export default ModifiersBoxHeader;
