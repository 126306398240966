import * as React from 'react';
import { IModifierGroupLocal } from '../../interfaces';
import { IModifierEditabilityPayload, IModifierPizzaSectionPayload } from '../action-creators';
import ModifiersGroup from '../components/customize-modifiers-group';

export interface IModifierCollectionsProps {
    modifierGroups: IModifierGroupLocal[];
}

export interface IModifierCollectionsDispatchProps {
    pizzaSectionClick?(payload: IModifierPizzaSectionPayload): void;
    increaseClick?(payload: IModifierEditabilityPayload): void;
    decreaseClick?(payload: IModifierEditabilityPayload): void;
}

type IProps = IModifierCollectionsProps & IModifierCollectionsDispatchProps;

const IngredientsList: React.FC<IProps> = (props: IProps) => {
    const { modifierGroups, pizzaSectionClick, increaseClick, decreaseClick } = props;

    const _renderModifiers = () =>
        modifierGroups.map((modifierGroup: IModifierGroupLocal, index: number) => (
            <ModifiersGroup
                key={index}
                pizzaSectionClick={pizzaSectionClick}
                modifierGroup={modifierGroup}
                increase={increaseClick}
                decrease={decreaseClick}
            />
        ));

    return <div className='as-modifiers-container'>{_renderModifiers()}</div>;
};

export default IngredientsList;
