import { combineReducers } from 'redux';
import customizeIngredientsReducer from '../customize-ingredients/reducer/customize-ingredients-reducer';
import customizeModifiersReducer from '../customize-modifiers/reducer/customize-modifiers-reducer';
import customizeConfiguratorReducer from '../customize-product-configurator/reducer/customize-configurator-reducer';
import customizeRecipeReducer from '../customize-recipe/reducer/customize-recipe-reducer';
import customizeSlotReducer from '../customize-slotComponent/reducer/customize-slotComponent-reducer';

export default combineReducers({
    customizeIngredients: customizeIngredientsReducer,
    customizeModifiers: customizeModifiersReducer,
    customizeRecipes: customizeRecipeReducer,
    customizeComponents: customizeSlotReducer,
    customizeConfigurator: customizeConfiguratorReducer
});
