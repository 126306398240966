import * as React from 'react';
import { IModifierLine, IRecipeWithData } from '../../interfaces';
import { IItemCheckPayload } from '../action-creators';
import RecipeItem from './recipe-item';

export interface IRecipesListProps {
    recipes?: IRecipeWithData[];
    onchange?(payload: IRecipeWithData[] | null, selectedIndex: number, modifierLines: IModifierLine[] | null): void;
}

export interface IRecipesListDispatchProps {
    itemCheck?(payload: IItemCheckPayload): void;
}

type Props = IRecipesListProps & IRecipesListDispatchProps;
const RecipesList: React.FC<Props> = (props: Props) => {
    const { recipes, itemCheck } = props;
    const _renderRecipes = () =>
        recipes?.map((recipe: IRecipeWithData) => <RecipeItem key={recipe.defaultId.toString()} item={recipe} check={itemCheck} />);
    return <div className='ingredients-item-container'>{recipes && _renderRecipes()}</div>;
};

export default RecipesList;
