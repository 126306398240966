import { connect } from 'react-redux';

import { loadModifiers } from '../action-creators';
import CustomizeModifiers from '../components/customize-modifiers';

const mapDispatchToProps = {
    loadModifiers
};

export default connect(null, mapDispatchToProps)(CustomizeModifiers);
