import { connect } from 'react-redux';
import { IState } from '../../interfaces';
import { decreaseClick, increaseClick, pizzaSectionClick } from '../action-creators';
import ModifiersCollection, {
    IModifierCollectionsDispatchProps,
    IModifierCollectionsProps
} from '../components/customize-modifiers-collection';

const mapStateToProps = (state: IState): IModifierCollectionsProps => {
    return {
        modifierGroups: state.customizeModifiers.modifierGroups
    };
};

const mapDispatchToProps: IModifierCollectionsDispatchProps = {
    pizzaSectionClick,
    increaseClick,
    decreaseClick
};

export default connect(mapStateToProps, mapDispatchToProps)(ModifiersCollection);
