import * as React from 'react';

export interface IIngredientsBoxHeaderProps {
    children: React.ReactElement | React.ReactElement[];
}

const IngredientsBoxHeader = (props: IIngredientsBoxHeaderProps): JSX.Element => {
    return <div className='as-modifiers-container'>{props.children}</div>;
};

export default IngredientsBoxHeader;
