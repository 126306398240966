import * as React from 'react';
import { IIngredientSubstitute } from '../../interfaces';
import IngredientSubstitueList from './ingredient-substitute-list';

export interface IIngredientSubstitueBoxProps {
    substitutes: IIngredientSubstitute[];
    name: string;
}

export interface IIngredientSubstitueBoxDispatchProps {
    closeSubstitueBox?(): void;
    substituteCheck?(payload: IIngredientSubstitute): void;
}

type Props = IIngredientSubstitueBoxProps & IIngredientSubstitueBoxDispatchProps;
const IngredientSubstitueBox: React.FC<Props> = (props): JSX.Element => {
    const _substituteInfo = () => {
        return (
            <div className='mx-auto substitute-header-container'>
                <h5 className='row-md-4 mx-auto substitute-header'>{`Substitution:`}</h5>
                <div role='none' className='row-md-4 substitute-item'>
                    {props.name}
                </div>
                <i className='fas fa-retweet fx-icon mx-auto' />
            </div>
        );
    };

    const _renderHeader = () => {
        return (
            <div className='col-12 header'>
                <div role='none' className='close' onClick={props.closeSubstitueBox}>
                    x
                </div>
                {_substituteInfo()}
            </div>
        );
    };

    return (
        <div className='substitute-box col-md-6'>
            {_renderHeader()}
            <IngredientSubstitueList substitutes={props.substitutes} substituteCheck={props.substituteCheck} />
        </div>
    );
};

export default IngredientSubstitueBox;
