import { ActionTypes } from '../../actions-types';
import {
    EditabilityText,
    IIngredientData,
    IIngredientSubGroups,
    IIngredientSubstitute,
    IIngredientWithSections,
    IModifierLine,
    LeftRightWhole
} from '../../interfaces';

export interface IInitialDispatch {
    type: ActionTypes.INITIAL_DISPATCH;
}

export const initialDispatch = (): IInitialDispatch => ({
    type: ActionTypes.INITIAL_DISPATCH
});

export interface ILoadIngredientsPayload {
    title: React.ReactNode;
    ingredient: IIngredientData[];
    ingredientSubGroups?: IIngredientSubGroups;
    allowLeftRight?: boolean;
    modifierLine?: IModifierLine[];
}

export interface ILoadIngredients {
    type: ActionTypes.LOAD_INGREDIENTS;
    payload: ILoadIngredientsPayload;
}

export const loadIngredients = (payload: ILoadIngredientsPayload): ILoadIngredients => ({
    type: ActionTypes.LOAD_INGREDIENTS,
    payload
});

export interface IPizzaSectionClickAction {
    type: ActionTypes.PIZZA_SECTION_CLICK;
    payload: LeftRightWhole;
}

export const pizzaSectionClick = (payload: LeftRightWhole): IPizzaSectionClickAction => ({
    type: ActionTypes.PIZZA_SECTION_CLICK,
    payload
});

export interface IItemCheckPayload {
    ingredient: IIngredientWithSections;
    checked: boolean;
}

export interface IItemCheck {
    type: ActionTypes.ITEM_CHECK;
    payload: IItemCheckPayload;
}

export const itemCheck = (payload: IItemCheckPayload): IItemCheck => ({
    type: ActionTypes.ITEM_CHECK,
    payload
});

export interface IEditabilityPayload {
    ingredient: IIngredientData;
    text: EditabilityText;
}

export interface IIncreaseClick {
    type: ActionTypes.INCREASE_CLICK;
    payload: IEditabilityPayload;
}

export const increaseClick = (payload: IEditabilityPayload): IIncreaseClick => ({
    type: ActionTypes.INCREASE_CLICK,
    payload
});

export interface IDecreaseClick {
    type: ActionTypes.DECREASE_CLICK;
    payload: IEditabilityPayload;
}

export const decreaseClick = (payload: IEditabilityPayload): IDecreaseClick => ({
    type: ActionTypes.DECREASE_CLICK,
    payload
});

export interface IGetSubstitutes {
    type: ActionTypes.GET_SUBSTITUES;
    payload: IIngredientData;
}

export const getSubstitute = (payload: IIngredientData): IGetSubstitutes => ({
    type: ActionTypes.GET_SUBSTITUES,
    payload
});

export interface ICloseSubstitueBox {
    type: ActionTypes.CLOSE_SUBSTITUTE_BOX;
}

export const closeSubstitueBox = (): ICloseSubstitueBox => ({
    type: ActionTypes.CLOSE_SUBSTITUTE_BOX
});

export interface ISubstituteCheck {
    type: ActionTypes.SUBSTITUTE_CHECK;
    payload: IIngredientSubstitute;
}

export const substituteCheck = (payload: IIngredientSubstitute): ISubstituteCheck => ({
    type: ActionTypes.SUBSTITUTE_CHECK,
    payload
});

export interface IMakeSubstitution {
    type: ActionTypes.MAKE_SUBSTITUTION;
    payload: IIngredientSubstitute;
}

export const makeSubstitution = (payload: IIngredientSubstitute): IMakeSubstitution => ({
    type: ActionTypes.MAKE_SUBSTITUTION,
    payload
});

export interface IItemClick {
    type: ActionTypes.ITEM_CLICK;
    payload: IIngredientWithSections;
}

export const itemClick = (payload: IIngredientWithSections): IItemClick => ({
    type: ActionTypes.ITEM_CLICK,
    payload
});

export type CustomizeIngredientsActions =
    | IPizzaSectionClickAction
    | IItemCheck
    | ILoadIngredients
    | IIncreaseClick
    | IDecreaseClick
    | IInitialDispatch
    | IGetSubstitutes
    | ICloseSubstitueBox
    | ISubstituteCheck
    | IMakeSubstitution
    | IItemClick;
