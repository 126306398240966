import {
    getPayloadObject,
    getTelemetryAttributes,
    IPayLoad,
    ITelemetryContent,
    TelemetryConstant
} from '@msdyn365-commerce-modules/utilities';
// import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';

/** Quantity Component Properties */
export interface IQuantityProps {
    /** The quantity min value */
    min?: number;

    /** The quantity max value */
    max: number;

    /** The quantity current value */
    currentCount?: number;

    /** sets if the quantity become button should be disabled */
    disabled?: boolean;

    /** sets icon class for decrement button */
    incrementGlyphClass?: string;

    /** sets icon class for increment button */
    decrementGlyphClass?: string;

    /** The decrement button aria-label */
    decrementButtonAriaLabel?: string;

    /** The increment button aria-label */
    incrementButtonAriaLabel?: string;

    /** The input quantity aria-label */
    inputQuantityAriaLabel?: string;

    /** The id for the component */
    id?: string;

    /** telemetry content */
    telemetryContent?: ITelemetryContent;

    /** Callback that is called when the value is decremented */
    onDecrement?(): void;

    /** Callback that is called when the value is incremented */
    onIncrement?(): void;
}

interface IQuantityState {
    currentInput: number;
    isUpdateing: boolean;
}
/**
 * Quantity Component - This component is used to add or remove quantity
 */
@observer
export default class Quantity extends React.PureComponent<IQuantityProps, IQuantityState> {
    public static defaultProps: Partial<IQuantityProps> = {
        min: 1,
        decrementGlyphClass: 'fas fa-minus',
        incrementGlyphClass: 'fas fa-plus'
    };

    private inputRef: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();
    private payLoad: IPayLoad;

    constructor(props: IQuantityProps) {
        super(props);
        this.state = { currentInput: props.currentCount || 1, isUpdateing: false };
        this._onIncrement = this._onIncrement.bind(this);
        this._onDecrement = this._onDecrement.bind(this);
        // this._handleChange = this._handleChange.bind(this);
        this.payLoad = getPayloadObject('click', this.props.telemetryContent!, '');
    }

    public componentDidUpdate(): void {
        if (this.props.disabled && !this.state.isUpdateing) {
            this.setState({ isUpdateing: true });
        }

        if (!this.props.disabled && this.state.isUpdateing) {
            this.setState({ currentInput: this.props.currentCount || 1, isUpdateing: false });
        }
    }

    public render(): JSX.Element {
        const glyphMinusClassName: string = `${this.props.decrementGlyphClass!} quantity__controls-glyph`;
        const glyphPlusClassName: string = `${this.props.incrementGlyphClass!} quantity__controls-glyph`;
        const disabled = this.state.currentInput === this.props.min;
        const incDisabled = this.state.currentInput === this.props.max;
        const currentValue = this.state.currentInput;
        this.payLoad.contentAction.etext = TelemetryConstant.DecrementQuantity;
        const decrementAttributes = getTelemetryAttributes(this.props.telemetryContent!, this.payLoad);
        this.payLoad.contentAction.etext = TelemetryConstant.IncrementQuantity;
        const incrementAttributes = getTelemetryAttributes(this.props.telemetryContent!, this.payLoad);
        let extraClass = '';
        if (disabled) {
            // The quantity has reached its boundaries (max or min)
            extraClass = 'disabled';
        } else if (this.props.disabled) {
            // this.props.disabled shows if the state is not succeded yet
            extraClass = 'transition';
        }
        let extraClassInc = '';
        if (incDisabled) {
            // The quantity has reached its boundaries (max or min)
            extraClassInc = 'disabled';
        } else if (this.props.disabled) {
            // this.props.disabled shows if the state is not succeded yet
            extraClassInc = 'transition';
        }
        return (
            <>
                <div className='quantity' id={this.props.id}>
                    <button
                        disabled={this.props.disabled || disabled}
                        title={disabled ? '' : this.props.decrementButtonAriaLabel}
                        className={`decrement quantity__controls ${extraClass}`}
                        onClick={this._onDecrement}
                        aria-hidden={true}
                        aria-label={`${this.props.decrementButtonAriaLabel}`}
                        tabIndex={-1}
                        color={'secondary'}
                        {...decrementAttributes}
                    >
                        <span className={glyphMinusClassName} />
                    </button>
                    <input
                        type='number'
                        className='quantity-input'
                        pattern='[0-9]*'
                        value={this.state.currentInput}
                        // onChange={this._handleChange}
                        // onBlur={this._validateMin}
                        aria-live='polite'
                        aria-label={`${this.props.inputQuantityAriaLabel}`}
                        role='spinbutton'
                        aria-valuemin={this.props.min}
                        aria-valuemax={this.props.max}
                        aria-valuenow={currentValue}
                        ref={this.inputRef}
                        disabled={this.props.disabled}
                    />
                    <button
                        disabled={this.props.disabled || incDisabled}
                        title={incDisabled ? '' : this.props.incrementButtonAriaLabel}
                        className={`increment quantity__controls ${extraClassInc}`}
                        onClick={this._onIncrement}
                        aria-hidden={true}
                        aria-label={`${this.props.incrementButtonAriaLabel}`}
                        tabIndex={-1}
                        color={'secondary'}
                        {...incrementAttributes}
                    >
                        <span className={glyphPlusClassName} />
                    </button>
                </div>
            </>
        );
    }

    private _onIncrement(): void {
        let invokeCallback = false;
        const currQuantity = this.state.currentInput;
        let updatedQuantity: number;

        if (currQuantity < this.props.max) {
            invokeCallback = true;
            updatedQuantity = currQuantity + 1;
        } else {
            invokeCallback = false;
            updatedQuantity = this.props.max;
        }

        this.setState({ currentInput: updatedQuantity });

        invokeCallback && this.props.onIncrement && this.props.onIncrement();
    }

    private _onDecrement(): void {
        let invokeCallback = false;
        const currQuantity = this.state.currentInput;
        let updatedQuantity: number;

        if (currQuantity > 1) {
            invokeCallback = true;
            updatedQuantity = currQuantity - 1;
        } else {
            invokeCallback = false;
            updatedQuantity = 1;
        }

        this.setState({ currentInput: updatedQuantity });

        invokeCallback && this.props.onDecrement && this.props.onDecrement();
    }

    // private _handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    //     const currentValue = parseInt((e.target.value), 10);
    //     const minValue = this.props.min === undefined ? 1 : this.props.min;
    //     const inputElement = this.inputRef && this.inputRef.current && this.inputRef.current instanceof HTMLInputElement && this.inputRef.current;

    //     if (currentValue > this.props.max) {
    //         this.setState(
    //             { currentInput: this.props.max },
    //             () => {
    //                 debounce(
    //                     () => {
    //                         this.props.onChange && this.props.onChange(this.state.currentInput);
    //                     },
    //                     200)();
    //             }
    //         );
    //     } else {
    //             this.setState(
    //                 { currentInput: currentValue },
    //                 () => {
    //                     debounce(
    //                         () => {
    //                             if(!isNaN(this.state.currentInput) && !(this.state.currentInput < minValue)) {
    //                                 this.props.onChange && this.props.onChange(this.state.currentInput);

    //                                 if (inputElement) {
    //                                     inputElement.setAttribute('aria-valuenow', currentValue.toString());
    //                                     inputElement.setAttribute('value', currentValue.toString());
    //                                 }
    //                             }
    //                         },
    //                         200)();
    //                 }
    //             );
    //     }
    // }

    // private _validateMin = (): void => {
    //     const minValue = this.props.min === undefined ? 1 : this.props.min;
    //     if(isNaN(this.state.currentInput) || (this.state.currentInput < minValue)) {
    //         this.setState({ currentInput: minValue }, () => { this.props.onChange && this.props.onChange(this.state.currentInput); });
    //     } else {
    //         this.props.onChange && this.props.onChange(this.state.currentInput);
    //     }
    // }
}
