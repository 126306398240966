import { buildCacheKey } from '@msdyn365-commerce-modules/retail-actions';
import {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    ICommerceApiSettings
} from '@msdyn365-commerce/core';
import { addCommerceListLinePropertyAsync, getCommerceListLinePropertyAsync } from './DataActionExtension.g';
import { IAddCommerceListLinePropertyResponse, IGetCommerceListLinePropertyResponse } from './DataServiceEntities.g';

export async function addCommerceListLinePropertyAction(
    retailWishlistTableRecId: string,
    retailWishlistLineRecId: string,
    commerceListLineProperties: string,
    ctx: IActionContext
): Promise<IAddCommerceListLinePropertyResponse> {
    return addCommerceListLinePropertyAsync(
        { callerContext: ctx, queryResultSettings: {} },
        retailWishlistTableRecId,
        retailWishlistLineRecId,
        commerceListLineProperties
    );
}

/**
 * Input class for GetCommerceListLinePropertyAsyncInput data action
 */
export class GetCommerceListLinePropertyAsyncInput implements IActionInput {
    public retailWishlistLineRecId: string;
    private apiSettings: ICommerceApiSettings;

    constructor(retailWishlistLineRecId: string, apiSettings: ICommerceApiSettings) {
        this.retailWishlistLineRecId = retailWishlistLineRecId;
        this.apiSettings = apiSettings;
    }

    public getCacheKey = () => buildCacheKey(`GetCommerceListLinePropertyResponseExtensionClass`, this.apiSettings);
    public getCacheObjectType = () => 'GetCommerceListLinePropertyResponseExtensionClass';
    public dataCacheType = (): CacheType => 'request';
}

export async function getCommerceListLinePropertyAsyncAction(
    input: GetCommerceListLinePropertyAsyncInput,
    ctx: IActionContext
): Promise<IGetCommerceListLinePropertyResponse> {
    return getCommerceListLinePropertyAsync({ callerContext: ctx, queryResultSettings: {} }, input.retailWishlistLineRecId);
}

export default createObservableDataAction({
    action: <IAction<IGetCommerceListLinePropertyResponse>>getCommerceListLinePropertyAsyncAction
});
