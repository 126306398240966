import {
    EditabilityText,
    IModifierData,
    IModifierGroup,
    IModifierGroupLocal,
    IModifierLine,
    IModifierLocal,
    LeftRightWhole,
    ModifierGroupType,
    ModifierLineType
} from '../../interfaces';

const getQtyForWhole = (modifierLine: IModifierLine[], modifierGroupId: number, modifierData: IModifierData): number =>
    modifierLine
        .filter(mLine => mLine.LeftRight === 0 && modifierGroupId === mLine.ModGroupId)
        .find(mLine => mLine.LineNum === modifierData.LineNum)?.Qty || 0;

const getQtyForLeft = (modifierLine: IModifierLine[], modifierGroupId: number, modifierData: IModifierData): number =>
    modifierLine
        .filter(mLine => mLine.LeftRight === 1 && modifierGroupId === mLine.ModGroupId)
        .find(mLine => mLine.LineNum === modifierData.LineNum)?.Qty || 0;

const getQtyForRight = (modifierLine: IModifierLine[], modifierGroupId: number, modifierData: IModifierData): number =>
    modifierLine
        .filter(mLine => mLine.LeftRight === 2 && modifierGroupId === mLine.ModGroupId)
        .find(mLine => mLine.LineNum === modifierData.LineNum)?.Qty || 0;

const populateInitialModifierGroups = (
    modifiers: IModifierData[],
    modifierGroupId: number,
    modifierLine: IModifierLine[]
): IModifierLocal[] => {
    const whole: IModifierLocal[] = modifiers.map(item => ({
        ...item,
        section: LeftRightWhole.whole,
        editabilityOption: EditabilityText.Normal,
        quantity: getQtyForWhole(modifierLine, modifierGroupId, item),
        modifierGroupId: modifierGroupId,
        checked: false,
        modifierLineType: getModifierLineType(item)
    }));
    const left: IModifierLocal[] = modifiers.map(item => ({
        ...item,
        section: LeftRightWhole.left,
        editabilityOption: EditabilityText.Normal,
        quantity: getQtyForLeft(modifierLine, modifierGroupId, item),
        modifierGroupId: modifierGroupId,
        checked: false,
        modifierLineType: getModifierLineType(item)
    }));
    const right: IModifierLocal[] = modifiers.map(item => ({
        ...item,
        section: LeftRightWhole.right,
        editabilityOption: EditabilityText.Normal,
        quantity: getQtyForRight(modifierLine, modifierGroupId, item),
        modifierGroupId: modifierGroupId,
        checked: false,
        modifierLineType: getModifierLineType(item)
    }));

    return [...whole, ...left, ...right];
};

const getModifierLineType = (modifier: IModifierData) => {
    // modifierGroupType and modifierLineType is actually never undefined
    let modifierLineType = ModifierLineType.Modifier;
    // map modifierLineType based on modifierGroupType
    if (modifier.GroupRef) {
        switch (modifier.GroupRef.Type) {
            case ModifierGroupType.Product:
                modifierLineType = ModifierLineType.Ingredient;
                break;
            case ModifierGroupType.Recipe:
                modifierLineType = ModifierLineType.Modifier;
                break;
            case ModifierGroupType.Substitutions:
                modifierLineType = ModifierLineType.Component;
                break;
            case ModifierGroupType.Text:
                modifierLineType = ModifierLineType.TextModifier;
                break;
            default:
        }
    }
    return modifierLineType;
};

const modifierLinesWithIncreaseDecreaseAction = (modifierLine: IModifierLine[]) =>
    modifierLine.filter(mLine => mLine.Action === 3 || mLine.Action === 4);

export const populateInitialModifiersCollection = (
    modifers: IModifierGroup[],
    modifierLine: IModifierLine[] = []
): IModifierGroupLocal[] => {
    return modifers.map(item => ({
        ...item,
        currentModifiers: populateInitialModifierGroups(item.List, item.GroupId, modifierLinesWithIncreaseDecreaseAction(modifierLine)),
        leftRightWhole: LeftRightWhole.whole,
        currentQuantity: modifierLine
            .filter(mLine => mLine.ModGroupId === item.GroupId)
            .reduce((acc, cur) => {
                return acc + cur.Qty;
            }, 0)
    }));
};
