import { buildCacheKey } from '@msdyn365-commerce-modules/retail-actions';
import {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    ICommerceApiSettings
} from '@msdyn365-commerce/core';
import { initializeAsync } from './DataActionExtension.g';
import { IProductConfiguration } from './DataServiceEntities.g';

/**
 * Input class for Initialize Product Configuration data action
 */
export class InitializeProductConfigurationExtInput implements IActionInput {
    public modelXml: string;
    public solverStrategy: number;
    public cartLineId: string;
    private apiSettings: ICommerceApiSettings;

    constructor(modelXml: string, solverStrategy: number, cartLineId: string, apiSettings: ICommerceApiSettings) {
        this.modelXml = modelXml;
        this.solverStrategy = solverStrategy;
        this.cartLineId = cartLineId;
        this.apiSettings = apiSettings;
    }

    public getCacheKey = () => buildCacheKey(`ProductConfigurationExtensionClass`, this.apiSettings);
    public getCacheObjectType = () => 'ProductConfigurationExtensionClass';
    public dataCacheType = (): CacheType => 'request';
}

export async function initializeProductConfigurationAction(
    input: InitializeProductConfigurationExtInput,
    ctx: IActionContext
): Promise<IProductConfiguration> {
    return initializeAsync({ callerContext: ctx, queryResultSettings: {} }, input.modelXml, input.solverStrategy, input.cartLineId);
}

export default createObservableDataAction({
    action: <IAction<IProductConfiguration>>initializeProductConfigurationAction
});
