import {
    EditabilityText,
    ICustomizeIngredients,
    IIngredientData,
    IIngredientSubstitute,
    IIngredientWithSections,
    ILeftRightWholeModifiersArray,
    IModifierLine,
    IngredientEditability,
    LeftRightSetting,
    LeftRightWhole,
    ModifierLineAction,
    ModifierLineType
} from '../../interfaces';

import { IEditabilityPayload, IItemCheckPayload, ILoadIngredientsPayload } from '../action-creators';

const restoreChecked = (modifierLines: IModifierLine[], ingredient: IIngredientData, section: LeftRightSetting): boolean => {
    if (section === LeftRightSetting.Whole) {
        return !modifierLines.find(
            modifierLine =>
                modifierLine.ItemId === ingredient.ItemId &&
                modifierLine.LineNum === ingredient.LineNum &&
                modifierLine.ProductId === ingredient.ProductRecId &&
                modifierLine.ParentProductId === ingredient.ProductId &&
                modifierLine.Qty === 0
        );
    } else {
        return !modifierLines.find(modifierLine => {
            if (modifierLine.LeftRight === LeftRightSetting.Whole) {
                return (
                    modifierLine.ItemId === ingredient.ItemId &&
                    modifierLine.LineNum === ingredient.LineNum &&
                    modifierLine.ProductId === ingredient.ProductId &&
                    modifierLine.ParentProductId === ingredient.ProductId &&
                    modifierLine.Qty === 0
                );
            } else {
                return (
                    modifierLine.ItemId === ingredient.ItemId &&
                    modifierLine.LeftRight === (section === LeftRightSetting.Left ? LeftRightSetting.Left : LeftRightSetting.Right) &&
                    modifierLine.LineNum === ingredient.LineNum &&
                    modifierLine.ProductId === ingredient.ProductId &&
                    modifierLine.ParentProductId === ingredient.ProductId &&
                    modifierLine.Qty === 0
                );
            }
        });
    }
};

const setIngredientWithModifiers = (
    ingredient: IIngredientWithSections,
    modifiers: IModifierLine[],
    section: number
): IngredientEditability | EditabilityText => {
    const modLine = modifiers && modifiers.find(x => x.ProductId === ingredient.ProductRecId && x.LeftRight === section);

    if (!modLine || modLine.Type !== ModifierLineType.Ingredient) {
        return EditabilityText.Normal;
    }

    switch (modLine.Action) {
        case ModifierLineAction.Increase:
            return EditabilityText.Extra;
        case ModifierLineAction.Decrease:
            return EditabilityText.Light;
        default:
    }

    return EditabilityText.Normal;
};

const populateInitialIngredients = (ingredients: IIngredientData[], modifierLines: IModifierLine[] = []): IIngredientWithSections[] => {
    return ingredients.reduce((acc: IIngredientWithSections[], currentItem: IIngredientData) => {
        const value = [0, 1, 2].map(section => {
            return {
                ...restoreSubstitution(<IIngredientWithSections>currentItem, modifierLines, section),
                checked: restoreChecked(modifierLines, currentItem, section),
                section:
                    section === LeftRightSetting.Whole
                        ? LeftRightWhole.whole
                        : section === LeftRightSetting.Left
                        ? LeftRightWhole.left
                        : LeftRightWhole.right,
                editabilityOption: setIngredientWithModifiers(<IIngredientWithSections>currentItem, modifierLines, section),
                substitutionLineNum: 0
            };
        });
        return [...acc, ...value];
    }, []);
};

export const restoreLeftRightAndWholeModifiersArrays = (ingredients: IIngredientWithSections[]): ILeftRightWholeModifiersArray => {
    const modifierArrayForWholeSection: IIngredientWithSections[] = [];
    const modifierArrayForLeftSection: IIngredientWithSections[] = [];
    const modifierArrayForRightSection: IIngredientWithSections[] = [];

    ingredients.map(x => {
        if (x.section === LeftRightWhole.whole && !x.checked) {
            modifierArrayForWholeSection.push(x);
        } else if (x.section === LeftRightWhole.left && !x.checked) {
            modifierArrayForLeftSection.push({
                ...x,
                LeftRight: LeftRightSetting.Left
            });
        } else if (x.section === LeftRightWhole.right && !x.checked) {
            modifierArrayForLeftSection.push({
                ...x,
                LeftRight: LeftRightSetting.Right
            });
        }
    });

    return {
        leftRightModifiersArray: [...modifierArrayForLeftSection, ...modifierArrayForRightSection],
        wholeModifiersArray: modifierArrayForWholeSection
    };
};

export const loadIngredients = (state: ICustomizeIngredients, payload: ILoadIngredientsPayload) => {
    const initialIngredients = populateInitialIngredients(payload.ingredient, payload.modifierLine);

    return {
        ...state,
        defaultIngredients: payload.ingredient,
        ingredientsForDifferentSections: initialIngredients,
        ...restoreLeftRightAndWholeModifiersArrays(initialIngredients),
        ingredientSubGroups: payload.ingredientSubGroups,
        allowLeftRight: payload.allowLeftRight,
        modifierLine: payload.modifierLine
    };
};

export const itemCheck = (state: ICustomizeIngredients, payload: IItemCheckPayload) => {
    const lineNumber = payload.ingredient.LineNum;
    const checked = payload.checked;
    const currentSection = state.leftRightWhole;
    const leftSection = currentSection === LeftRightWhole.left;
    const rightSection = currentSection === LeftRightWhole.right;
    const wholeSection = currentSection === LeftRightWhole.whole;

    const ingredientsForDifferentSectionsTempArray = state.ingredientsForDifferentSections
        .map(item => {
            if (item.LineNum === lineNumber && item.section === currentSection) {
                item.checked = checked;
            }
            return item;
        })
        .map((item: IIngredientWithSections) => {
            // Make LEFT / RIGHT select, when WHOLE section is selected
            if (wholeSection && checked && item.LineNum === lineNumber && item.section !== currentSection) {
                item.checked = true;
            }

            // Make LEFT / RIGHT unselect, when WHOLE section is unselected
            if (wholeSection && !checked && item.LineNum === lineNumber && item.section !== currentSection) {
                item.checked = false;
            }

            // Make WHOLE unselect, when LFET / RIGHT section is unselected
            if ((leftSection || rightSection) && !checked && item.LineNum === lineNumber && item.section === LeftRightWhole.whole) {
                item.checked = false;
            }

            return item;
        });

    let newWholeModifiersArray: IIngredientWithSections[];
    const currentWholeModifiersArray = [...state.wholeModifiersArray];

    if (wholeSection) {
        const index = currentWholeModifiersArray.findIndex(item => item.LineNum === lineNumber);
        newWholeModifiersArray = checked
            ? [...currentWholeModifiersArray.slice(0, index), ...currentWholeModifiersArray.slice(index + 1)]
            : [...currentWholeModifiersArray, payload.ingredient];
    } else {
        newWholeModifiersArray = [...state.wholeModifiersArray];
    }
    const modifierArrayForLeftSection = ingredientsForDifferentSectionsTempArray
        .filter(item => item.section === LeftRightWhole.left && !item.checked)
        .map(item => ({ ...item, LeftRight: LeftRightSetting.Left }));

    const modifierArrayForRightSection = ingredientsForDifferentSectionsTempArray
        .filter(item => item.section === LeftRightWhole.right && !item.checked)
        .map(item => ({ ...item, LeftRight: LeftRightSetting.Right }));

    return {
        ...state,
        ingredientsForDifferentSections: ingredientsForDifferentSectionsTempArray,
        leftRightModifiersArray: [...modifierArrayForLeftSection, ...modifierArrayForRightSection],
        wholeModifiersArray: newWholeModifiersArray,
        substitutes: [],
        substitutionIngredient: undefined
    };
};

export const restoreSubstitution = (
    ingredient: IIngredientWithSections,
    modifiers: IModifierLine[],
    section: number
): IIngredientWithSections => {
    if (ingredient.Edit !== IngredientEditability.Substitute) {
        return ingredient;
    }

    const modLine = modifiers && modifiers.find(y => y.LineNum === ingredient.LineNum && y.LeftRight === section);

    if (modLine === null || modLine === undefined || !modLine.LineNum) {
        return ingredient;
    }

    return {
        ...ingredient,
        ItemId: modLine.ItemId,
        Name: modLine.Name,
        substitutionLineNum: modLine.LineNum,
        itemLineNum: modLine.LineNum
    };
};

export const makeSubstitution = (state: ICustomizeIngredients, payload: IIngredientSubstitute) => {
    const currentSection = state.leftRightWhole;
    const index = state.ingredientsForDifferentSections.findIndex(
        item => item.section === currentSection && item.SubGroupId === state.currentSubGroupId
    );
    const itemToBeSubstituted = state.ingredientsForDifferentSections[index];
    return {
        ...state,
        ingredientsForDifferentSections: [
            ...state.ingredientsForDifferentSections.slice(0, index),
            {
                ...itemToBeSubstituted,
                Name: payload.Name,
                substitutionLineNum: payload.ItemId === itemToBeSubstituted.ItemId ? 0 : payload.LineNum,
                itemLineNum: payload.ItemId === itemToBeSubstituted.ItemId ? 0 : itemToBeSubstituted.LineNum
            },
            ...state.ingredientsForDifferentSections.slice(index + 1)
        ]
    };
};

export const increaseDecreaseClick = (state: ICustomizeIngredients, payload: IEditabilityPayload) => {
    return {
        ...state,
        ingredientsForDifferentSections: state.ingredientsForDifferentSections.map(item => {
            if (item.LineNum === payload.ingredient.LineNum && item.section === state.leftRightWhole) {
                item.editabilityOption = payload.text;
            }
            return item;
        })
    };
};

export const getSubstitutes = (state: ICustomizeIngredients, payload: IIngredientData) => {
    const ingredientSubGroups = (state.ingredientSubGroups && state.ingredientSubGroups[payload.SubGroupId]) || [];
    return {
        ...state,
        substitutes: ingredientSubGroups.filter(item => item.Name !== payload.Name),
        substitutionIngredient: payload,
        currentSubGroupId: payload.SubGroupId
    };
};

export const closeSubstituteBox = (state: ICustomizeIngredients): ICustomizeIngredients => {
    return {
        ...state,
        substitutes: [],
        substitutionIngredient: undefined
    };
};
